import * as yup from 'yup';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useContentElement } from '@plugins/next-cms-core';
import slugify from 'slugify';

export default function OnPageNavigationTargetBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    OnPageNavigationTargetBlock.dataSchema,
  );

  const { id, label, anchorName } = elementData;
  const anchorId = getAnchorRefName(id, anchorName);

  return (
    <div className={classes.root}>
      <a className={classes.target} id={anchorId} title={label} />
    </div>
  );
}

export function getAnchorRefName(id, name) {
  return name ? slugify(name) : `onpage-nav-index-${id}`;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: '1px',
  },
  target: {
    position: 'absolute',
    display: 'block',
    userSelect: 'none',
    top: -120,
    left: 0,
    height: '1px',
    width: '100%',
  },
}));

OnPageNavigationTargetBlock.typeName = 'ComponentContentOnPageNavigationTarget'; // Strapi element type
OnPageNavigationTargetBlock.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    anchorName: PropTypes.string.isRequired,
  }).isRequired,
};
OnPageNavigationTargetBlock.dataSchema = yup.object().shape({
  label: yup.string().required(),
  anchorName: yup.string().nullable(),
});
OnPageNavigationTargetBlock.graphQlSchema = `
... on ${OnPageNavigationTargetBlock.typeName} {
  id
  label
  anchorName
}
`;
