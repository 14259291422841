import * as yup from 'yup';

import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useContentElement } from '@plugins/next-cms-core';
import Container from '../atoms/Container';

export default function PublishDateBlock(props) {
  const { data, context } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    PublishDateBlock.dataSchema,
  );

  const date = DateTime.fromISO(
    context.page?.attributes?.publishDate
    ?? context.page?.attributes?.publishedAt
    ?? context.page?.publishDate
    ?? context.page?.publishedAt,
  );

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="caption">
          Veröffentlicht am:
          {' '}
          {date.toFormat('dd.MM.yyyy')}
        </Typography>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderStyle: 'solid',
    borderColor: theme.palette.tertiary.main,
    borderTopWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    marginBottom: theme.spacing(-3),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
}));

PublishDateBlock.typeName = 'ComponentContentPublishDate'; // Strapi element type
PublishDateBlock.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  context: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
};
PublishDateBlock.dataSchema = yup.object().shape({
});
PublishDateBlock.graphQlSchema = `
... on ${PublishDateBlock.typeName} {
  id
}
`;
