import {
  Box, Button, Card, CardActionArea, CardContent, Typography, useTheme,
} from '@material-ui/core';
import { MediaElement, RibbonElement, TypographyElement } from '@plugins/next-cms-core';

import { DateTime } from 'luxon';
import { FiArrowRight } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Link from '../atoms/Link';
import { animationDefaults, easings, timings } from '../../theme';

export default function CampaignPreviewItem(props) {
  const { item } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const isExpired = item.attributes.expirationDate && DateTime
    .fromISO(item.attributes.expirationDate) < DateTime.now();
  const isBlogItem = item.attributes.pageType === 'blogItem';

  const { url } = item.attributes;
  const date = DateTime.fromISO(item.attributes.publishDate ?? item.attributes.publishedAt);

  return (
    <Card className={classes.root} elevation={3} style={{ position: 'relative' }}>
      <Link href={url}>
        <CardActionArea className={classes.tileActionArea}>
          {item.attributes.blogPreviewMedia && (
            <div className={classes.mediaContainer}>
              <MediaElement
                className={classes.previewImage}
                data={item.attributes.blogPreviewMedia}
                isFluid
              />
              {item.blogPreviewMediaRibbon && (
                <RibbonElement data={item.attributes.blogPreviewMediaRibbon} />
              )}
            </div>
          )}
          <CardContent className={classes.tileContent}>
            <div>
              <Typography color="primary" variant="body2">
                {date.toFormat('dd.MM.yyyy')}
              </Typography>
              <Typography component="h4" variant="h5">
                {item.attributes.name}
              </Typography>
              <Box my={3}>
                {item.attributes.blogPreviewText && (
                  <TypographyElement data={item.attributes.blogPreviewText} />
                )}
              </Box>
            </div>
            <Box>
              <Link href={url} legacyBehavior>
                <Button
                  color="primary"
                  component="a"
                  endIcon={<FiArrowRight />}
                  fullWidth
                  variant="outlined"
                >
                  {t('components.molecules.CampaignPreviewItem.learnMore')}
                </Button>
              </Link>
            </Box>
          </CardContent>
        </CardActionArea>
      </Link>
      {isExpired && (
        <Box style={{
          top: 0,
          left: 0,
          width: 0,
          height: 0,
          position: 'absolute',
          borderTop: `125px solid ${theme.palette.primary.main}`,
          borderRight: '125px solid transparent',
          pointerEvents: 'none',
        }}
        >
          <Typography
            style={{
              position: 'absolute',
              color: 'white',
              textAlign: 'center',
              lineHeight: '1.1em',
              fontWeight: '900',
              transform: 'rotate(-45deg) translateY(-64px) translateX(80px)',
            }}
            variant="subtitle1"
          >
            {t('components.molecules.CampaignPreviewItem.actionExpired')}
          </Typography>
        </Box>
      )}
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    transition: `all ${timings.slowAnimationTiming}ms ${easings.defaultEasingCss}`,
    '&:hover': {
      transform: `scale(${animationDefaults.defaultScale})`,
    },
    '& *': {
      textDecoration: 'none !important',
    },
  },
  tileActionArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textDecoration: 'none !important',
  },
  tileContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mediaContainer: {
    position: 'relative',
  },
  previewImage: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  blogItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
  },
  blogItemContainer: {
    display: 'flex',
  },
  blogItemCover: {
    width: 300,
  },
  blogItemTitle: {
    color: theme.palette.text.hint,
  },
}));

CampaignPreviewItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    publishedAt: PropTypes.string.isRequired,
    publishDate: PropTypes.string,
    blogPreviewText: PropTypes.shape(TypographyElement.propTypes),
    blogPreviewMedia: PropTypes.shape(MediaElement.propTypes),
  }),
};
CampaignPreviewItem.defaultProps = {
  item: {},
};

CampaignPreviewItem.graphQlSchema = `
id
attributes {
  name
  url
  slug
  pageType
  publishedAt
  publishDate
  isPinnedToTop
  expirationDate
  tags {
    data {
      id
    }
  }
  blogPreviewText {
    ${TypographyElement.graphQlSchema}
  }
  blogPreviewMedia {
    ${MediaElement.graphQlSchema}
  }
  blogPreviewMediaRibbon {
    ${RibbonElement.graphQlSchema}
  }
}
`;
