import * as yup from 'yup';

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  ButtonElement,
  LinkElement,
  MediaElement,
  TypographyElement,
  useContentElement,
} from '@plugins/next-cms-core';

import { FiArrowRight } from 'react-icons/fi';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import Container from '../atoms/Container';
import EmissionLabel from '../atoms/EmissionLabel';
import { animationDefaults, easings, timings } from '../../theme';

export default function CarPromotionBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    CarPromotionBlock.dataSchema,
  );

  const {
    cars,
  } = elementData;

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      textAlign: 'center',
      semanticVariant: 'h2',
      displayVariant: 'h3',
    });
  }

  return (
    <div className={classes.root}>
      <Box>
        <Container className={classes.container}>
          {title && (
            <Box mb={5}>
              <TypographyElement data={title} />
            </Box>
          )}
          <Grid
            alignItems="stretch"
            container
            justifyContent="center"
            spacing={5}
          >
            {cars.map((car) => (
              <Grid
                key={car.id}
                className={classes.carContainer}
                item
                lg={4}
                md={6}
                xs={12}
              >
                <Car classes={classes} item={car} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

function Car(props) {
  const {
    classes,
    item,
  } = props;

  const {
    title,
    subtitle,
    consumptionInnercity,
    consumptionOutercity,
    consumptionCombined,
    consumptionCombinedElectricity,
    consumptionCombinedHydrogen,
    emissions,
    energyEfficiencyClass,
    consumptionSuffix,
    media,
    link,
    text,
    prices,
    buttons,
  } = item;

  const carDetails1 = [];
  const carDetails2 = [];

  if (!isNil(consumptionInnercity)) {
    carDetails1.push({
      label: 'innerorts',
      value: consumptionInnercity.toLocaleString('de-DE'),
      suffix: 'l/100 km',
    });
  }

  if (!isNil(consumptionOutercity)) {
    carDetails1.push({
      label: 'außerorts',
      value: consumptionOutercity.toLocaleString('de-DE'),
      suffix: 'l/100 km',
    });
  }

  if (!isNil(consumptionCombined)) {
    carDetails1.push({
      label: 'kombiniert',
      value: consumptionCombined.toLocaleString('de-DE'),
      suffix: 'l/100 km',
    });
  }

  if (!isNil(consumptionCombinedElectricity)) {
    carDetails1.push({
      label: <span>Strom&shy;verbrauch kombiniert</span>,
      value: consumptionCombinedElectricity.toLocaleString('de-DE'),
      suffix: 'kWh/100 km',
    });
  }

  if (!isNil(consumptionCombinedHydrogen)) {
    carDetails1.push({
      label: <span>Wasserstoff&shy;verbrauch kombiniert</span>,
      value: consumptionCombinedHydrogen.toLocaleString('de-DE'),
      suffix: 'kg/100 km',
    });
  }

  if (!isNil(emissions)) {
    carDetails2.push({
      label:
  <span>
    CO
    <sub><small>2</small></sub>
    -Emissionen (kombiniert)
  </span>,
      value: emissions,
      suffix: 'g/km',
    });
  }

  if (energyEfficiencyClass) {
    carDetails2.push({
      label:
  <span>
    CO
    <sub><small>2</small></sub>
    -Klasse
  </span>,
      value: <EmissionLabel efficiency={energyEfficiencyClass} />,
    });
  }

  let lastRowWasSummary = false;

  return (
    <Card className={classes.carRoot} elevation={3}>
      <LinkElement data={link ?? {}}>
        <CardActionArea className={classes.carActionArea}>
          {media && (
            <MediaElement
              className={classes.carMedia}
              data={media}
              isFluid
            />
          )}
          <CardContent className={classes.cardContent}>
            {subtitle && (
              <Typography
                color="primary"
                component="div"
                variant="subtitle1"
              >
                {subtitle}
              </Typography>
            )}
            {title && (
              <Typography component="h3" variant="h5">
                {title}
              </Typography>
            )}
            {text && text.value && (
              <Box mt={2}>
                <TypographyElement data={text} />
              </Box>
            )}
            {(carDetails1.length > 0 || carDetails2.length > 0) && (
              <Box mt={2}>
                <Typography component="div" variant="subtitle2">
                  Kraftstoffverbrauch
                  {
                    consumptionSuffix === '*'
                      ? <span>*</span>
                      : <sup>{consumptionSuffix}</sup>
                  }
                </Typography>
                <Box my={1}>
                  <Divider />
                </Box>
                <CarDetailsList
                  items1={carDetails1}
                  items2={carDetails2}
                />
              </Box>
            )}
            {prices && prices.length > 0 && (
              <Box className={classes.carPrices} mt={2}>
                {prices.map((priceRow, index) => {
                  const isLastRow = index === prices.length - 1;
                  const components = [];

                  if (index > 0
                    && !lastRowWasSummary
                    && priceRow.isSummary
                  ) {
                    lastRowWasSummary = true;
                    components.push(
                      <Box key="divider" my={1}>
                        <Divider />
                      </Box>,
                    );
                  }

                  components.push(
                    <Box
                      key={priceRow.id}
                      alignItems="center"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography component="div" variant="subtitle2">
                        {priceRow.label}
                        <sup>
                          {priceRow.labelSuffix}
                        </sup>
                      </Typography>
                      <Typography
                        component="div"
                        style={{ fontSize: isLastRow ? '1.25em' : 'inherit' }}
                        variant="subtitle2"
                      >
                        {priceRow.value}
                        <sup>
                          {priceRow.valueSuffix}
                        </sup>
                      </Typography>
                    </Box>,
                  );

                  return components;
                })}
              </Box>
            )}
          </CardContent>
        </CardActionArea>
      </LinkElement>
      {buttons && buttons.length > 0 && (
        <CardActions>
          <Grid container spacing={1}>
            {buttons.map((button) => (
              <Grid key={button.id} item sm={6} xs={12}>
                <ButtonElement
                  color="primary"
                  data={button}
                  endIcon={<FiArrowRight />}
                  fullWidth
                  variant="contained"
                />
              </Grid>
            ))}
          </Grid>
        </CardActions>
      )}
    </Card>
  );
}

Car.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
};

Car.defaultProps = {
  classes: {},
  item: {},
};

function CarDetailsList({
  items1,
  items2,
}) {
  const renderItems = (items) => items.map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Grid key={index} alignItems="center" container spacing={1}>
      <Grid item xs={6}>
        <Typography component="div" variant="caption">
          {item.label}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component="div" variant="caption">
          {item.value}
          {' '}
          {item.suffix}
        </Typography>
      </Grid>
    </Grid>
  ));

  return (
    <Grid container>
      {items1 && items1.length > 0 && (
        <Grid item sm={6} xs={12}>
          {renderItems(items1)}
        </Grid>
      )}
      {items1 && items1.length > 0 && (
        <Grid item sm={6} xs={12}>
          {renderItems(items2)}
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  cardContent: {
    paddingBottom: 0,
  },
  carContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  carRoot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    transition: `all ${timings.slowAnimationTiming}ms ${easings.defaultEasingCss}`,
    '&:hover': {
      transform: `scale(${animationDefaults.defaultScale})`,
    },
  },
  carActionArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textDecoration: 'none !important',
  },
  carMedia: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  carPrices: {
    backgroundColor: theme.palette.quaternary.main,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
}));

CarPromotionBlock.typeName = 'ComponentContentCarPromotion'; // Strapi element type
CarPromotionBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    cars: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      consumptionInnercity: PropTypes.number,
      consumptionOutercity: PropTypes.number,
      consumptionCombined: PropTypes.number,
      consumptionCombinedElectricity: PropTypes.number,
      consumptionCombinedHydrogen: PropTypes.number,
      emissions: PropTypes.number,
      energyEfficiencyClass: PropTypes.string,
      consumptionSuffix: PropTypes.string,
      prices: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        labelSuffix: PropTypes.string,
        value: PropTypes.string,
        valueSuffix: PropTypes.string,
        isSummary: PropTypes.bool,
      })),
    })),
  }).isRequired,
};
CarPromotionBlock.dataSchema = yup.object()
  .shape({
    title: TypographyElement.dataSchema.nullable(),
    cars: yup.array()
      .of(yup.object()
        .shape({
          title: yup.string()
            .nullable(),
          subtitle: yup.string()
            .nullable(),
          consumptionInnercity: yup.number()
            .nullable(),
          consumptionOutercity: yup.number()
            .nullable(),
          consumptionCombined: yup.number()
            .nullable(),
          consumptionCombinedElectricity: yup.number()
            .nullable(),
          consumptionCombinedHydrogen: yup.number()
            .nullable(),
          emissions: yup.number()
            .nullable(),
          energyEfficiencyClass: yup.string()
            .nullable(),
          consumptionSuffix: yup.string()
            .nullable(),
          media: MediaElement.dataSchema.nullable(),
          link: LinkElement.dataSchema.nullable(),
          text: TypographyElement.dataSchema.nullable(),
          prices: yup.array()
            .of(yup.object()
              .shape({
                label: yup.string()
                  .nullable(),
                labelSuffix: yup.string()
                  .nullable(),
                value: yup.string()
                  .nullable(),
                valueSuffix: yup.string()
                  .nullable(),
                isSummary: yup.bool()
                  .nullable(),
              })),
          buttons: yup.array()
            .of(ButtonElement.dataSchema),
        })),
  });
CarPromotionBlock.graphQlSchema = `
... on ${CarPromotionBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  cars {
    id
    title
    subtitle
    consumptionInnercity
    consumptionOutercity
    consumptionCombined
    consumptionCombinedElectricity
    consumptionCombinedHydrogen
    emissions
    energyEfficiencyClass
    consumptionSuffix
    media {
      ${MediaElement.graphQlSchema}
    }
    link {
      ${LinkElement.graphQlSchema}
    }
    text {
      ${TypographyElement.graphQlSchema}
    }
    prices {
      id
      label
      labelSuffix
      value
      valueSuffix
      isSummary
    }
    buttons {
      ${ButtonElement.graphQlSchema}
    }
  }
}
`;
