import * as yup from 'yup';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useConsent } from '@lib/gdpr';
import { useContentElement } from '@plugins/next-cms-core';
import Container from '../atoms/Container';
import ConsentRequiredFallback from '../molecules/ConsentRequiredFallback';

export default function SoftgardenBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const hasConsent = useConsent('softgarden');

  // eslint-disable-next-line no-unused-vars
  useContentElement(
    data,
    SoftgardenBlock.dataSchema,
  );

  if (!hasConsent) {
    return <ConsentRequiredFallback />;
  }

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <iframe
          className={classes.frame}
          height="108"
          src="https://certificate.softgarden.io/widget/schimmel-automobile/de"
          title="Softgarden"
          width="256"
        />
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  frame: {
    border: 'none',
  },
}));

SoftgardenBlock.typeName = 'ComponentContentSoftgardenRatings'; // Strapi element type
SoftgardenBlock.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
};
SoftgardenBlock.dataSchema = yup.object().shape({

});
SoftgardenBlock.graphQlSchema = `
... on ${SoftgardenBlock.typeName} {
  id
}
`;
