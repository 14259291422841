import { Box, Button, Grid } from '@material-ui/core';
import { memo } from 'react';

function FormActions(props) {
  const {
    form,
    dirty,
    isValid,
    isSubmitting,
  } = props;

  return (
    <Box mt={3}>
      <Grid container justifyContent="center">
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Button
            color="primary"
            disabled={!dirty || !isValid || isSubmitting}
            fullWidth
            type="submit"
            variant="contained"
          >
            {form.sendButtonLabel || 'Senden'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(FormActions);
