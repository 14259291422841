import * as yup from 'yup';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { FiCalendar, FiX } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useContentElement } from '@plugins/next-cms-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { collapsedHeightDesktop, collapsedHeightMobile } from '../organisms/Header';

import Container from '../atoms/Container';
import Link from '../atoms/Link';
import ModelRequestForm from '../../forms/ModelRequestForm';
import { zIndices } from '../../theme';

export default function ModelRequestFormBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isFormVisible, setFormVisible] = useState(false);
  const { elementData } = useContentElement(
    data,
    ModelRequestFormBlock.dataSchema,
  );

  const stickThresholdTop = parseInt((isMobile
    ? collapsedHeightMobile
    : collapsedHeightDesktop
  ).replace('px', ''), 10) - 2;
  const {
    modelRequestFormTitle: title,
    modelRequestFormSubtitle: subtitle,
    modelRequestFormPreEnteredMessage: preEnteredMessage,
    modelRequestFormButtonInfoText: buttonInfoText,
    modelRequestFormButtonLabel: buttonLabel,
    formColors,
    formEquipmentLines,
  } = elementData;

  const handleToggleForm = () => {
    setFormVisible(!isFormVisible);
  };

  return (
    <div
      className={classes.root}
      style={{ top: stickThresholdTop }}
    >
      <Container className={classes.container}>
        <Grid alignItems="center" container spacing={3}>
          <Hidden smDown>
            <Grid item md={6} xs={12}>
              <Typography
                className={classes.title}
                color="secondary"
                component="span"
                variant="h5"
              >
                {title}
              </Typography>
              {' '}
              <Typography
                className={classes.secondaryText}
                color="secondary"
                component="span"
              >
                {subtitle}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item md={6} xs={12}>
            <Box alignItems="center" display="flex" justifyContent="flex-end">
              <Box mr={3}>
                <Typography
                  className={classes.secondaryText}
                  color="secondary"
                  component="span"
                >
                  {buttonInfoText || t('components.contentTypes.ModelRequestFormBlock.buttonInfoText')}
                </Typography>
              </Box>
              <Button
                className={classes.toggleFormButton}
                color="secondary"
                onClick={handleToggleForm}
                size="small"
                variant="contained"
              >
                {buttonLabel || t('components.contentTypes.ModelRequestFormBlock.consultas')}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth="lg"
          onClose={handleToggleForm}
          open={isFormVisible}
          scroll="body"
        >
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <DialogTitle>
              {buttonLabel || t('components.contentTypes.ModelRequestFormBlock.buttonLabel')}
            </DialogTitle>
            <Box pr={1}>
              <IconButton onClick={handleToggleForm}>
                <FiX />
              </IconButton>
            </Box>
          </Box>
          <DialogContent>
            {!buttonLabel && (
              <>
                <Box>
                  <Link href="https://www.etermin.net/schimmel" legacyBehavior>
                    <Button
                      color="primary"
                      component="a"
                      rel="noopener nofollow"
                      startIcon={
                        <FiCalendar />
                      }
                      target="_blank"
                      variant="contained"
                    >
                      {t('components.contentTypes.ModelRequestFormBlock.arrangeTestDriveNow')}
                    </Button>
                  </Link>
                </Box>
                <Box mb={3} mt={5}>
                  <Divider />
                </Box>
              </>
            )}
            <Box mb={3}>
              {!buttonLabel && (
                <Typography variant="h6">
                  {t('components.contentTypes.ModelRequestFormBlock.individualRequest')}
                </Typography>
              )}
              <ModelRequestForm
                formFields={{
                  colors: formColors,
                  equipmentLines: formEquipmentLines,
                }}
                message={preEnteredMessage}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    position: 'sticky',
    zIndex: zIndices.modelRequestForm,
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    overflowX: 'auto',
  },
  formContainerStuck: {
    marginBottom: 300,
  },
  title: {
    fontWeight: '900',
  },
  secondaryText: {
    // opacity: 0.75
  },
  toggleFormButton: {
    minWidth: 140,
  },
}));

ModelRequestFormBlock.typeName = 'ComponentContentModelRequestForm'; // Strapi element type
ModelRequestFormBlock.propTypes = {
  data: PropTypes.shape({
    modelRequestFormTitle: PropTypes.string,
    modelRequestFormSubtitle: PropTypes.string,
    modelRequestFormPreEnteredMessage: PropTypes.string,
  }).isRequired,
};
ModelRequestFormBlock.dataSchema = yup.object().shape({
  modelRequestFormTitle: yup.string().nullable(),
  modelRequestFormSubtitle: yup.string().nullable(),
  modelRequestFormPreEnteredMessage: yup.string().nullable(),
});
ModelRequestFormBlock.graphQlSchema = `
... on ${ModelRequestFormBlock.typeName} {
  id
  modelRequestFormTitle: title
  modelRequestFormSubtitle: subtitle
  modelRequestFormPreEnteredMessage: preEnteredMessage
  modelRequestFormButtonInfoText: buttonInfoText
  modelRequestFormButtonLabel: buttonLabel
  formColors
  formEquipmentLines
}
`;
