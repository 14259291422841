/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';

import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  MediaElement, TypographyElement, ButtonElement, useContentElement,
} from '@plugins/next-cms-core';
import React, { useState } from 'react';

import { FiChevronDown } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import defaults from 'lodash/defaults';
import Container from '../atoms/Container';
import ReadMoreContainer from '../atoms/ReadMoreContainer';

export default function AccordionSliderBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const [expandedPanel, setExpandedPanel] = useState(0);
  const { elementData } = useContentElement(
    data,
    AccordionSliderBlock.dataSchema,
  );

  const { accordionSliderSlides } = elementData;
  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  if (!accordionSliderSlides || accordionSliderSlides.length === 0) {
    return null;
  }

  const currentSlide = accordionSliderSlides[expandedPanel];

  const handleExpandPanel = (index) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? index : 0);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        {title && (
          <Box mb={5}>
            <TypographyElement data={title} />
          </Box>
        )}
        <Grid container justifyContent="center" spacing={3}>
          <Grid item md={7} xs={12}>
            <MediaElement
              data={currentSlide.media}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            {accordionSliderSlides.map((item, itemIndex) => (
              <ExpansionPanel
                key={itemIndex}
                expanded={expandedPanel === itemIndex}
                onChange={handleExpandPanel(itemIndex)}
              >
                <ExpansionPanelSummary
                  expandIcon={<FiChevronDown />}
                >
                  <Typography component="h3" style={{ marginRight: 'auto' }} variant="subtitle2">
                    {item.category}
                    {' '}
                  </Typography>
                  {item.suffix && expandedPanel !== itemIndex && (
                    <Typography component="h3" variant="subtitle2">
                      {item.suffix}
                    </Typography>
                  )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Box>
                    {item.title && (
                      <Box mb={3}>
                        <Typography
                          className={classes.panelTitle}
                          variant="h3"
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    )}
                    <ReadMoreContainer
                      data={item}
                      expandedPanel={expandedPanel}
                      itemIndex={itemIndex}
                      suffix={item.suffix}
                    />
                  </Box>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  panelTitle: {
    '& > p': {
      margin: 0,
    },
  },
}));

AccordionSliderBlock.typeName = 'ComponentContentAccordion'; // Strapi element type
AccordionSliderBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    accordionSliderSlides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      category: PropTypes.string.isRequired,
      media: MediaElement.propTypes,
      text: TypographyElement.propTypes,
    })),
  }).isRequired,
};

AccordionSliderBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema.nullable(),
  accordionSliderSlides: yup.array().of(yup.object().shape({
    id: yup.number().required(),
    title: yup.string().nullable(),
    category: yup.string().required(),
    media: MediaElement.dataSchema.required(),
    text: TypographyElement.dataSchema,
  })),
});

AccordionSliderBlock.graphQlSchema = `
... on ${AccordionSliderBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  accordionSliderSlides: slides {
    id
    category
    suffix
    media {
      ${MediaElement.graphQlSchema}
    }
    title
    text {
      ${TypographyElement.graphQlSchema}
    }
    buttons {
      ${ButtonElement.graphQlSchema}
    }
  }
}
`;
