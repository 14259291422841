import {
  Box, Card, CardActionArea, CardContent, Grid, Typography,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';

import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Branches({
  t, data, setData, onGoNext,
}) {
  const router = useRouter();
  const classes = useStyles();
  const { i18n } = useTranslation();

  const { error, data: branchData, loading } = useQuery(FETCH_BRANCHES, {
    variables: {
      locale: i18n.language,
    },
  });

  if (error) {
    throw error;
  }

  const branches = branchData?.branches.data ?? [];

  const handleSelectBranch = (branch) => () => {
    setData({
      ...data,
      branch: {
        id: branch.id,
        ...branch.attributes,
      },
      calendarCount: null,
      calendarId: null,
      categories: [],
      selectedServices: [],
      selectedDate: null,
      selectedTime: null,
    });
    onGoNext();
  };

  useEffect(() => {
    const selectedBranch = branches.find((b) => b.id === router.query?.branch);
    if (selectedBranch) {
      handleSelectBranch(selectedBranch)();
    }
  }, [router.query?.branch, branches]);

  return (
    <div>
      <Box mb={3}>
        <Typography data-e2e-id="step-title" variant="h6">
          {t('components.organisms.WorkShopAppointmentTool.steps.Branches.question')}
        </Typography>
      </Box>
      {loading && (
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Skeleton height={100} variant="rect" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton height={100} variant="rect" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton height={100} variant="rect" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton height={100} variant="rect" />
          </Grid>
        </Grid>
      )}
      {!loading && (
        <Grid container spacing={3}>
          {branches.map((branch) => (
            <Grid key={branch.id} item sm={6} xs={12}>
              <Branch
                branch={branch}
                classes={classes}
                onSelect={handleSelectBranch}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}

function Branch(props) {
  const {
    classes,
    branch,
    onSelect,
  } = props;

  return (
    <Card>
      <CardActionArea
        data-e2e-id="branch"
        data-e2e-value={branch.id}
        onClick={onSelect(branch)}
      >
        <CardContent>
          <Typography
            className={classes.title}
            gutterBottom
            variant="subtitle1"
          >
            {branch.attributes.name}
          </Typography>
          <Typography variant="body2">
            {branch.attributes.addressStreet}
            <br />
            {branch.attributes.addressZipcode}
            {' '}
            {branch.address_city}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  service: {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  serviceSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  iconAdd: {
    color: theme.palette.primary.main,
  },
  iconRemove: {
    color: '#4CAF50',
  },
  title: {
    fontWeight: 'bold',
  },
}));

// eslint-disable-next-line func-names
Branches.validateStep = function (data) {
  return Boolean(data.branch);
};

export const BRANCH_PROPERTIES = `
  id
  attributes {
    name
    addressStreet
    addressZipcode
    addressCity
    primaryImage {
      data {
        id
        attributes {
          name
          formats
          url
        }
      }
    }
  }
`;

const FETCH_BRANCHES = gql`
  query GetItems($locale: I18NLocaleCode!) {
    branches(sort: "name:ASC", locale: $locale) {
      data {
        ${BRANCH_PROPERTIES}
      }
    }
  }
`;
