import * as yup from 'yup';

import { Box, Grid } from '@material-ui/core';
import {
  ButtonElement, ColorScheme, TypographyElement, useContentElement,
} from '@plugins/next-cms-core';

import { FiArrowRight } from 'react-icons/fi';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import Container from '../atoms/Container';

export default function HeroBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    HeroBlock.dataSchema,
  );

  const { buttons } = elementData;
  let { colorScheme } = elementData;
  const title = omitBy(elementData.heroTitle, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const subtitle = omitBy(elementData.heroSubtitle, isNull);
  defaults(subtitle, {
    semanticVariant: 'h4',
    displayVariant: 'subtitle1',
  });

  const text = omitBy(elementData.heroText, isNull);
  defaults(text, {
    semanticVariant: 'div',
    displayVariant: 'body1',
    textAlign: 'justify',
  });

  colorScheme = ColorScheme.getColorScheme(colorScheme, 'secondary');
  const cssColorClass = `${colorScheme[0].toUpperCase()}${colorScheme.substring(1)}`;

  return (
    <div className={clsx(classes.root, classes[`root${cssColorClass}`])}>
      <Container className={classes.container}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Box mb={text.value ? 3 : 0}>
            <TypographyElement
              className={classes.title}
              color={MUI_COLOR_MAPPING[colorScheme]}
              data={title}
            />
            <TypographyElement
              className={classes.subtitle}
              color={MUI_COLOR_MAPPING[colorScheme]}
              data={subtitle}
            />
          </Box>
          {text.value && (
            <Grid
              className={classes.textContainer}
              container
              justifyContent="center"
            >
              <Grid item lg={9} md={10} xs={12}>
                <TypographyElement
                  color={MUI_COLOR_MAPPING[colorScheme]}
                  data={text}
                />
              </Grid>
            </Grid>
          )}
          {buttons && buttons.length > 0 && (
            <Box mt={3}>
              <Grid
                container
                justifyContent="center"
                spacing={3}
              >
                {buttons.map((button) => (
                  <Grid
                    key={button.id}
                    item
                    md="auto"
                    xs={12}
                  >
                    <ButtonElement
                      color={MUI_COLOR_MAPPING[colorScheme]}
                      component="a"
                      data={button}
                      endIcon={<FiArrowRight />}
                      fullWidth
                      size="large"
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  rootPrimary: {
    backgroundColor: theme.palette.secondary.main,
  },
  rootSecondary: {
    backgroundColor: theme.palette.primary.main,
  },
  rootTertiary: {
    backgroundColor: theme.palette.tertiary.main,
  },
  rootQuaternary: {
    backgroundColor: theme.palette.quaternary.main,
  },
  rootLight: {
    backgroundColor: theme.palette.light.main,
  },
  rootDark: {
    backgroundColor: theme.palette.dark.main,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  textContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(9),
    },
  },
  title: {
    '& p': {
      margin: 0,
    },
  },
  subtitle: {
    '& p': {
      margin: 0,
      fontWeight: 'bold',
    },
  },
}));

HeroBlock.typeName = 'ComponentContentHero'; // Strapi element type
HeroBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.dataSchema,
    text: TypographyElement.propTypes,
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonElement.propTypes)),
    colorScheme: ColorScheme.propTypes,
  }).isRequired,
};
HeroBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema,
  subtitle: TypographyElement.dataSchema.nullable(),
  text: TypographyElement.dataSchema,
  buttons: yup.array(ButtonElement.dataSchema).nullable(),
  colorScheme: ColorScheme.dataSchema.nullable(),
});
HeroBlock.graphQlSchema = `
... on ${HeroBlock.typeName} {
  id
  heroTitle: title {
    ${TypographyElement.graphQlSchema}
  }
  heroSubtitle: subtitle {
    ${TypographyElement.graphQlSchema}
  }
  heroText: text {
    ${TypographyElement.graphQlSchema}
  }
  buttons {
    ${ButtonElement.graphQlSchema}
  }
  colorScheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;

const MUI_COLOR_MAPPING = {
  default: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'inherit',
  quaternary: 'inherit',
  light: 'inherit',
  dark: 'secondary',
};
