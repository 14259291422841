import * as yup from 'yup';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import { Box, Paper } from '@material-ui/core';
import { EmbedElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';
import dynamic from 'next/dynamic';
import Container from '../atoms/Container';

const EmbedElementDynamic = dynamic(() => import('@plugins/next-cms-core/components/elements/EmbedElement'), { ssr: false });

export default function EmbedBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    EmbedBlock.dataSchema,
  );

  const { embed } = elementData;
  const title = omitBy(elementData.title, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {title && (
          <Box mb={5}>
            <TypographyElement
              align="center"
              data={title}
            />
          </Box>
        )}
        <Container className={classes.contentContainer}>
          <Paper elevation={18} square>
            <div className={classes.embed}>
              <EmbedElementDynamic
                className={classes.player}
                data={embed}
                options={{
                  // https://developers.google.com/youtube/player_parameters
                  modestbranding: 1,
                  rel: 0,
                }}
              />
            </div>
          </Paper>
        </Container>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    maxWidth: 800,
  },
  embed: {
    // Responsive embed
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: 0,
    height: 0,
  },
  player: {
    '&, & object, & embed': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
}));

EmbedBlock.typeName = 'ComponentContentEmbed'; // Strapi element type
EmbedBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    embed: EmbedElement.propTypes,
  }).isRequired,
};
EmbedBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema.nullable(),
  embed: EmbedElement.dataSchema,
});
EmbedBlock.graphQlSchema = `
... on ${EmbedBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  embed {
    ${EmbedElement.graphQlSchema}
  }
}
`;
