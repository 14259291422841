/* eslint-disable no-restricted-syntax,no-underscore-dangle */
import React, { memo, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { getFormDisplayState, getInitialFormValues, helper } from '@lib/form';
import { DateTime, Duration } from 'luxon';
import { Box, Grid } from '@material-ui/core';
import { gql, useMutation } from '@apollo/client';
import { getFieldType } from '@lib/form/helper';
import { useSendEmail } from '@lib';
import { useRouter } from 'next/router';
import { trackEvent } from '@components/organisms/Tracking';
import { create } from 'xmlbuilder2';
import FormFooter from './FormFooter';
import FormActions from './FormActions';
import { FORM_FIELDS } from './fieldMapping';
import FormFieldGroup from './FormFieldGroup';
import { layoutWidth } from '../../../constants';

const SERIALIZATION_DEPENDENCIES = { DateTime };

function Form(props) {
  const { form, setFormVisible, queries } = props;
  const [validationSchema, setValidationSchema] = useState({});
  const router = useRouter();
  const [submitFormValues] = useMutation(SUBMIT_FORM_VALUES);
  const sendEmail = useSendEmail();
  const initialValues = getInitialFormValues(
    form,
    [],
    FORM_FIELDS,
    SERIALIZATION_DEPENDENCIES,
    queries,
  );

  const handleSubmit = async (values) => {
    try {
      const formDisplayState = getFormDisplayState(form, values);
      let displayValues = getDisplayValues(form, values, formDisplayState);
      const xmlDoc = generateXml(displayValues);

      displayValues = {
        ...displayValues,
        meta: {
          pageUrl: `https://www.schimmel-automobile.de${router.asPath}`,
        },
      };

      await submitFormValues({
        variables: {
          input: {
            form: form.id,
            values: {
              ...displayValues,
              attachments: [
                {
                  content: window.btoa(xmlDoc.toString()),
                  filename: 'dealerdesk.xml',
                  type: 'application/xml',
                  disposition: 'attachment',
                },
              ],
            },
          },
        },
      });

      if (form.recipientEmailTemplateId && form.recipientEmail) {
        const data = {
          to: form.recipientEmail,
          cc: form.cc?.map((r) => r.email) ?? [],
          bcc: form.bcc?.map((r) => r.email) ?? [],
          templateId: form.recipientEmailTemplateId,
          dynamicTemplateData: displayValues,
          attachments: [
            {
              content: window.btoa(xmlDoc.toString()),
              filename: 'dealerdesk.xml',
              type: 'application/xml',
              disposition: 'attachment',
            },
          ],
        };

        await sendEmail(data);
      }

      if (form.sendReceiptEmailToIssuer
        && form.receiptEmailTemplateId
        && displayValues.email?.fieldValue?.value
      ) {
        const data = {
          to: displayValues.email.fieldValue.value,
          cc: form.cc?.map((r) => r.email) ?? [],
          bcc: form.bcc?.map((r) => r.email) ?? [],
          templateId: form.receiptEmailTemplateId,
          dynamicTemplateData: displayValues,
        };

        await sendEmail(data);
      }

      trackEvent('Lead', { content_name: form.name });

      if (form.postSubmitAction?.[0]?.url) {
        router.push(form.postSubmitAction[0].url);
      }

      setFormVisible(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <InnerForm
          form={form}
          groups={form.items ?? []}
          setValidationSchema={setValidationSchema}
          validationSchema={validationSchema}
          {...formikProps}
        />
      )}
    </Formik>
  );
}

Form.propTypes = {
  form: PropTypes.object.isRequired,
  setFormVisible: PropTypes.func.isRequired,
  queries: PropTypes.object,
  postSubmitAction: PropTypes.array,
};

Form.defaultProps = {
  queries: {},
  postSubmitAction: [],
};

const FormMemo = memo(Form);

export default FormMemo;

function InnerForm(props) {
  const {
    form,
    groups,
    values,
    dirty,
    errors,
    isValid,
    isSubmitting,
    handleSubmit,
    validationSchema,
    setValidationSchema,
  } = props;

  const formDisplayState = getFormDisplayState(form, values);
  const newValidationSchema = getValidationSchema(form, formDisplayState);

  useEffect(() => {
    setValidationSchema(newValidationSchema);
  }, [
    Object.keys(validationSchema?.fields ?? {}).length
    === Object.keys(newValidationSchema.fields ?? {}).length,
    setValidationSchema,
  ]);

  if (dirty && !isValid) {
    console.warn(errors, values);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Grid
          container
          justifyContent="flex-end"
          spacing={3}
        >
          {groups.filter((group) => !group.isConditional).map((group) => (
            <Grid
              key={group.id}
              item
              md={layoutWidth[group.layout?.width] ?? 12}
              xs={12}
            >
              <FormFieldGroup
                fields={group.fields}
                form={form}
                formDisplayState={formDisplayState}
                id={group.id}
                isCollapsed={group.isCollapsed}
                title={group.title}
                values={values}
                {...props}
              />
            </Grid>
          ))}
        </Grid>
        <FormFooter />
        <FormActions
          dirty={dirty}
          form={form}
          isSubmitting={isSubmitting}
          isValid={isValid}
        />
      </Box>
    </form>
  );
}

InnerForm.propTypes = {
  form: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  setValidationSchema: PropTypes.func.isRequired,
};

function getDisplayValues(form, values, displayState) {
  const displayValues = {};

  for (const group of form.items) {
    for (const field of group.fields) {
      const fieldId = field.id;
      const formFieldType = getFieldType(field)?.__typename;

      if (!formFieldType) {
        continue;
      }

      const formFieldConfig = getFieldType(field);
      const key = `field_${fieldId}`;
      const context = {
        key,
        values,
      };

      if (displayState[key].isVisible) {
        const value = values[`field_${fieldId}`];
        const fieldValue = FORM_FIELDS[formFieldType]
          .serializeValue(value, formFieldConfig, { DateTime }, context);
        const displayValue = FORM_FIELDS[formFieldType]
          .getDisplayValue({ value }, formFieldConfig, { DateTime }, context);

        displayValues[field.name] = {
          groupId: group.id,
          fieldId: field.id,
          groupTitle: group.title,
          fieldLabel: field.label,
          fieldValue,
          displayValue,
        };
      }
    }
  }

  return displayValues;
}

function getValidationSchema(form, formDisplayState) {
  const fieldValidations = {};

  for (const group of form.items) {
    for (const field of group.fields) {
      const fieldId = field.id;

      if (!formDisplayState[`field_${fieldId}`].isVisible) {
        continue;
      }

      const formFieldType = helper.getFieldType(field)?.__typename;
      const formFieldConfig = helper.getFieldType(field);

      if (field.isHidden) {
        continue;
      }

      if (!formFieldType) {
        continue;
      }

      const key = `field_${fieldId}`;
      let validationSchema = FORM_FIELDS[formFieldType].validationSchema(
        formFieldConfig,
        {
          key,
          DateTime,
          Duration,
          fieldValidations,
        },
      );

      if (validationSchema) {
        if (field.isRequired) {
          validationSchema = validationSchema.required(
            `${field.label} ist erforderlich`,
          );
        }

        fieldValidations[key] = validationSchema;
      }
    }
  }

  return yup.object().noUnknown().shape(fieldValidations);
}

const SUBMIT_FORM_VALUES = gql`
  mutation Submit($input: FormValueInput!){
    createFormValue(data: $input) {
      data {
        id
      }
    }
  }
`;

const generateXml = (values, vehicle) => {
  // eslint-disable-next-line no-nested-ternary
  const salution = values.salutation.displayValue ? values.salutation.displayValue === 'Frau' ? 'MS' : 'MR' : '';
  const doc = create({ version: '1.0', encoding: 'UTF-8' })
    .ele('dealerdeskLead')
    .ele('generalInformation')
    .ele('type')
    .txt('OPPORTUNITY')
    .up()
    .ele('subject')
    .txt('Individuelle Anfrage')
    .up()
    .ele('channel')
    .txt('WEBSITE')
    .up()
    .ele('dealerId')
    .up()
    .ele('sourceId')
    .up()
    .ele('contextLink')
    .up()
    .ele('urgency')
    .txt('NORMAL')
    .up()
    .ele('preferredContactMethod')
    .up()
    .ele('preferredContactDetails')
    .up()
    .ele('earliestContactTime')
    .up()
    .ele('latestContactTime')
    .up()
    .ele('externalReference')
    .up()
    .ele('escalationGroupId')
    .up()
    .up()
    .ele('contact')
    .ele('status')
    .txt('PROSPECT')
    .up()
    .ele('type')
    .txt('PRIVATE')
    .up()
    .ele('salutation')
    .txt(salution)
    .up()
    .ele('dateOfBirth')
    .up()
    .ele('companyName')
    .up()
    .ele('fullName')
    .txt(`${values.first_name.displayValue} ${values.last_name.displayValue}`)
    .up()
    .ele('namePrefix')
    .up()
    .ele('givenName')
    .txt(values.first_name.displayValue)
    .up()
    .ele('familyName')
    .txt(values.last_name.displayValue)
    .up()
    .ele('address1')
    .up()
    .ele('address2')
    .up()
    .ele('zip')
    .up()
    .ele('city')
    .up()
    .ele('state')
    .up()
    .ele('country')
    .up()
    .ele('phone')
    .txt(values.phone.displayValue)
    .up()
    .ele('email')
    .txt(values.email.displayValue)
    .up()
    .ele('externalReference')
    .up()
    .up()
    .ele('requestedVehicle')
    .ele('internalId')
    .up()
    .ele('link')
    .txt(window.location.href)
    .up()
    .ele('vehicleClass')
    .txt('CAR')
    .up()
    .ele('make')
    .up()
    .ele('model')
    .up()
    .ele('modelDescription')
    .up()
    .ele('vin')
    .up()
    .ele('usageType')
    .up()
    .ele('mileage')
    .up()
    .ele('price')
    .up()
    .ele('firstRegistration')
    .up()
    .ele('fuel')
    .up()
    .ele('power')
    .up()
    .ele('exteriorColor')
    .up()
    .ele('cubicCapacity')
    .up()
    .ele('preOffer')
    .up()
    .up()
    .ele('acquisition')
    .ele('tradeInRequested')
    .up()
    .ele('registrationRequested')
    .up()
    .ele('insuranceRequested')
    .up()
    .ele('deliveryRequested')
    .up()
    .ele('testdriveRequested')
    .up()
    .ele('preferredTimeOfTestdrive')
    .up()
    .ele('counterOffer')
    .up()
    .ele('dealName')
    .up()
    .ele('acquisitionType')
    .up()
    .ele('paybackPeriodMonths')
    .up()
    .ele('totalMileage')
    .up()
    .ele('firstInstallment')
    .up()
    .ele('monthlyInstallment')
    .up()
    .ele('finalInstallment')
    .up()
    .up()
    .ele('link')
    .ele('url')
    .txt(window.location.href)
    .up()
    .ele('description')
    .txt('Link zur Website')
    .up()
    .ele('key')
    .txt('csb-schimmel-atuomobile')
    .up()
    .up()
    .end({ prettyPrint: false });

  return doc;
};
