import * as yup from 'yup';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import { FiShoppingCart } from 'react-icons/fi';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import { getCarPartsShopRoute } from '@lib/routing';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import Link from '../atoms/Link';
import Container from '../atoms/Container';

export default function CarPartsShopBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const router = useRouter();
  const { i18n, t } = useTranslation();
  const { elementData } = useContentElement(
    data,
    CarPartsShopBlock.dataSchema,
  );

  const {
    dealerid,
    series,
  } = elementData;
  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
    });
  }

  const url = getCarPartsShopRoute({
    dealerid: dealerid ? dealerid.substring(0, dealerid.indexOf('_')) : null,
    series,
    locale: i18n.language,
  });
  let image = 'carpartsshop-teaser';

  if (router.asPath) {
    if (router.asPath.startsWith('/hyundai')) {
      image = 'carpartsshop-teaser-hyundai';
    } else if (router.asPath.startsWith('/mitsubishi')) {
      image = 'carpartsshop-teaser-mitsubishi';
    }
  }

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Paper elevation={10}>
          <Grid alignItems="stretch" container>
            <Grid
              className={classes.mediaContainer}
              item
              md={6}
              style={{
                backgroundImage: `url("/images/${image}.jpg")`,
              }}
              xs={12}
            />
            <Grid className={classes.textContainer} item>
              {title && (
                <Box mb={3}>
                  <TypographyElement data={title} />
                </Box>
              )}
              <Box mb={5}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: t('components.contentTypes.CarPartsShopBlock.carPartsShopText'),
                  }}
                />
                <Typography>
                  <br />
                  <br />
                  <strong>
                    {t('components.contentTypes.CarPartsShopBlock.carPartsShopText2')}
                  </strong>
                </Typography>
              </Box>
              <Box>
                <Link href={url} legacyBehavior>
                  <Button
                    color="primary"
                    component="a"
                    startIcon={<FiShoppingCart />}
                    variant="contained"
                  >
                    {t('components.contentTypes.CarPartsShopBlock.partsShop')}
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  container: {
  },
  mediaContainer: {
    display: 'flex',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    [theme.breakpoints.down('sm')]: {
      minHeight: 300,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    [theme.breakpoints.up('md')]: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
  },
}));

CarPartsShopBlock.typeName = 'ComponentContentCarpartsshop'; // Strapi element type
CarPartsShopBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propType,
    dealerid: PropTypes.string,
    series: PropTypes.string,
    model: PropTypes.string,
    hsn: PropTypes.string,
    tsn: PropTypes.string,
    fin: PropTypes.string,
    currencycode: PropTypes.string,
    display: PropTypes.string,
  }).isRequired,
};
CarPartsShopBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema.nullable(),
  dealerid: yup.string().nullable(),
  series: yup.string().nullable(),
  model: yup.string().nullable(),
  hsn: yup.string().nullable(),
  tsn: yup.string().nullable(),
  fin: yup.string().nullable(),
  currencycode: yup.string().nullable(),
  display: yup.string().nullable(),
});
CarPartsShopBlock.graphQlSchema = `
... on ${CarPartsShopBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  dealerid
  series
  model
  hsn
  tsn
  fin
  currencycode
  display
}
`;
