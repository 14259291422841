/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';

import {
  Box, Grid, Paper, useTheme,
} from '@material-ui/core';
import {
  ButtonElement, MediaElement, TypographyElement, useContentElement,
} from '@plugins/next-cms-core';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Virtual } from 'swiper';

import Container from '@components/atoms/Container';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import { useState } from 'react';
import StaffMember from '@components/molecules/StaffMember';

SwiperCore.use([Virtual]);
SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);

export default function TextEmployeeBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    TextEmployeeBlock.dataSchema,
  );

  const variant = elementData.textEmployeeBlockVariant ?? 'Plain';
  const order = elementData.textEmployeeBlockOrder ?? 'TextMedia';

  const items = [];
  const imageElement = {
    classes: [classes.mediaContainer],
    element: <EmployeeBlock classes={classes} data={elementData} variant={variant} />,
  };
  const textElement = {
    classes: [classes.textContainer],
    element: <TextBlock classes={classes} data={elementData} variant={variant} />,
  };
  const rootClasses = [classes.root];

  switch (order) {
    case 'MediaText':
      items.push(imageElement);
      items.push(textElement);
      rootClasses.push(classes.orderImageText);
      break;
    default:
      items.push(textElement);
      items.push(imageElement);
      rootClasses.push(classes.orderTextImage);
      break;
  }

  const columnSpan = variant === 'Card' ? 6 : 6;
  const columnSpacing = variant === 'Card' ? 0 : 10;

  if (variant === 'Card') {
    rootClasses.push(classes.variantCard);
  }

  return (
    <div className={clsx(rootClasses)}>
      <Container className={classes.container}>
        <Box my={10}>
          <Grid
            alignItems="center"
            container
            spacing={columnSpacing}
          >
            {items.map((item, index) => (
              <Grid
                key={index}
                className={clsx(item.classes)}
                item
                md={columnSpan}
                xs={12}
              >
                {item.element}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

function EmployeeBlock(props) {
  const { classes, data, variant } = props;
  const theme = useTheme();
  const {
    employees,
  } = data;
  const [swiper, setSwiper] = useState();

  if (employees.length === 0) {
    return null;
  }

  return (
    <Swiper
      autoplay={{
        delay: 5000,
      }}
      className={classes.swipeContainer}
      modules={[Pagination]}
      onSwiper={(newValue) => setSwiper(newValue)}
      pagination={{ clickable: true }}
      slidesPerView={1}
      spaceBetween={theme.spacing(2.5)}
    >
      {employees.data.map((employee) => (
        <SwiperSlide
          key={employee.id}
          className={classes.swipeSlideItem}
        >
          <StaffMember
            employee={employee}
            variant="horizontal"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

function TextBlock(props) {
  const { variant, data, classes } = props;
  const { buttons } = data;

  const title = omitBy(data.textEmployeeBlockTitle, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const subtitle = omitBy(data.textEmployeeBlockSubtitle, isNull);
  defaults(subtitle, {
    semanticVariant: 'div',
    displayVariant: 'body2',
  });

  const text = omitBy(data.textEmployeeBlockText, isNull);
  defaults(text, {
    semanticVariant: 'div',
    displayVariant: 'body1',
  });

  const content = (
    <>
      {subtitle?.value && (
        <Box mb={2}>
          <TypographyElement
            className={classes.textSubTitle}
            color="primary"
            data={subtitle}
          />
        </Box>
      )}
      {title?.value && (
        <Box mb={3}>
          <TypographyElement
            className={classes.textTitle}
            data={title}
          />
        </Box>
      )}
      {text?.value && (
        <Box>
          <TypographyElement data={text} />
          {buttons && (
            <Box mt={5}>
              <Grid container spacing={2}>
                {buttons.map((button) => (
                  <Grid key={button.id} item>
                    <ButtonElement
                      color="primary"
                      component="a"
                      data={button}
                      variant="contained"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </>
  );

  if (variant === 'Card') {
    return (
      <Paper elevation={3}>
        <Box p={5}>
          {content}
        </Box>
      </Paper>
    );
  }

  return content;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  container: {},
  textContainer: {
    zIndex: 1,
  },
  swipeContainer: {
    padding: 7,
    margin: -7,
    paddingTop: 20,
    height: '100%',
    '& .swiper-wrapper': {
      alignItems: 'stretch',
    },
    '& .swiper-pagination-bullet-active': {
      background: theme.palette.primary.main,
    },
    '& .swiper-container': {
      position: 'relative',
    },

    '& .swiper-pagination': {
      position: 'absolute',
      top: 0,
      display: 'flex',
      height: '10px',
      zIndex: 15,
    },

    '& .swiper-pagination-bullet': {
      borderRadius: 15,
      width: '100%',
      background: theme.palette.primary.main,
    },

    swipeSlideItem: {
      display: 'flex',
    },
  },
  mediaContainer: {
    zIndex: 0,
  },
  mediaLabel: {
    flex: 1,
    marginTop: theme.spacing(0.5),
    // position: 'absolute',
    // bottom: `calc(${(theme.typography.caption.lineHeight) * -1}em - ${theme.spacing(0.5)}px)`,
  },
  mediaTypeVideo: {
    display: 'block',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    height: 'auto',
  },
  orderImageText: {
    '& $mediaContainer': {
      transformOrigin: 'left center',
    },
    '& $mediaLabel': {
      left: theme.shape.borderRadius,
    },
  },
  orderTextImage: {
    '& $mediaContainer': {
      transformOrigin: 'right center',
    },
    '& $mediaLabel': {},
  },
  variantCard: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    '& $mediaContainer': {
      transform: 'scale(1.2)',
    },
    '& $mediaLabel': {
      maxWidth: '70%',
    },
  },
  textTitle: {
    '& p': {
      marginTop: 0,
    },
  },
  textSubTitle: {
    '& p': {
      margin: 0,
    },
  },
}));

const PROPTYPE_VARIANT = PropTypes.oneOf(['Plain', 'Card']);

TextEmployeeBlock.typeName = 'ComponentContentTextEmployee'; // Strapi element type
TextEmployeeBlock.propTypes = {
  data: PropTypes.shape({
    textEmployeeBlockVariant: PROPTYPE_VARIANT,
    textEmployeeBlockOrder: PropTypes.oneOf(['TextMedia', 'MediaText']),
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonElement.propTypes)),
    employees: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.propTypes,
      name: PropTypes.string.propTypes,
      position: PropTypes.string.propTypes,
      email: PropTypes.string.propTypes,
      phone: PropTypes.string.propTypes,
      media: MediaElement.propTypes,
    })),
  }).isRequired,
};
TextEmployeeBlock.dataSchema = yup.object().shape({
  textEmployeeBlockVariant: yup.string().nullable().oneOf([null, 'Plain', 'Card']),
  textEmployeeBlockOrder: yup.string().nullable().oneOf([null, 'TextMedia', 'MediaText']),
  employees: yup.object().shape({
    data: yup.array().of(yup.object().shape({
      id: yup.number().nullable(),
      name: yup.string().nullable(),
      position: yup.string().nullable(),
      email: yup.string().nullable(),
      phone: yup.string().nullable(),
      media: yup.array(MediaElement.dataSchema).nullable(),
    })),
  }),
  textEmployeeBlockTitle: TypographyElement.dataSchema,
  textEmployeeBlockSubtitle: TypographyElement.dataSchema,
  textEmployeeBlockText: TypographyElement.dataSchema,
  buttons: yup.array(ButtonElement.dataSchema).nullable(),
});
TextEmployeeBlock.graphQlSchema = `
... on ${TextEmployeeBlock.typeName} {
  id
  textEmployeeBlockVariant: variant
  textEmployeeBlockOrder: order
  textEmployeeBlockTitle: title {
    ${TypographyElement.graphQlSchema}
  }
  textEmployeeBlockSubtitle: subtitle {
    ${TypographyElement.graphQlSchema}
  }
  textEmployeeBlockText: text {
    ${TypographyElement.graphQlSchema}
  }
  buttons {
    ${ButtonElement.graphQlSchema}
  }
  employees {
    data {
      id
      attributes {
        name
        position
        phone
        phoneWhatsapp
        email
        image {
          ${MediaElement.graphQlSchema}
        }
        primary_branch {
          data {
            attributes {
              name
              pageUrl
            }
          }
        }
        languages(sort: "name") {
          data {
            id
            attributes {
              name
              emoji
            }
          }
        }
      }
    }
  }
}
`;

TextBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    variant: PROPTYPE_VARIANT,
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
  }).isRequired,
};
EmployeeBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PROPTYPE_VARIANT,
  data: PropTypes.shape({
    media: PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.oneOf(['image', 'video']),
    }),
  }).isRequired,
};

EmployeeBlock.defaultProps = {
  variant: 'Card',
};
