import {
  Box, List, ListItem, ListItemIcon, ListItemText, Paper, Typography,
} from '@material-ui/core';

import Image from '@components/atoms/Image';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

export default function Mobility({ t, data, setData }) {
  const classes = useStyles();
  const selectedMobility = data.mobility;

  const handleToggleMobility = (mobility) => () => {
    setData({
      ...data,
      mobility,
    });
  };

  return (
    <div className={classes.root}>
      <Box mb={3}>
        <Typography variant="h6">
          {t('components.organisms.WorkShopAppointmentTool.steps.Mobility.question')}
        </Typography>
      </Box>
      <Paper>
        <Box px={1} py={0.5}>
          <List disablePadding>
            {OPTIONS.map((option) => (
              <ListItem
                key={option.id}
                button
                className={clsx(classes.mobility, {
                  [classes.mobilitySelected]: selectedMobility?.id === option.id,
                })}
                data-e2e-id="mobility"
                onClick={handleToggleMobility(option)}
              >
                <ListItemIcon>
                  {option.icon}
                </ListItemIcon>
                <ListItemText>
                  {t(`components.organisms.WorkShopAppointmentTool.steps.Mobility.${option.id}`)}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  mobility: {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  mobilitySelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  iconAdd: {
    color: theme.palette.primary.main,
  },
  iconRemove: {
    color: '#4CAF50',
  },
}));

Mobility.validateStep = function (data) {
  return Boolean(data.mobility);
};

const OPTIONS = [
  {
    id: 'no-mobility',
    title: 'Keine Mobilität',
    icon: <Image alt="" height="24" src="/images/icons/icon-mobility-none.svg" style={{ display: 'block' }} width="24" />,
  },
  {
    id: 'replacement-car',
    title: 'Ersatzwagen',
    icon: <Image alt="" height="24" src="/images/icons/icon-mobility-replacement-car.svg" style={{ display: 'block' }} width="24" />,
  },
];
