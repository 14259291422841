import * as yup from 'yup';
import {
  Box, CardActionArea, Grid, Paper, Typography,
} from '@material-ui/core';
import {
  MediaElement, RibbonElement, TypographyElement, useContentElement,
} from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import Container from '../atoms/Container';
import Link from '../atoms/Link';

export default function SimilarPagesBlock(props) {
  const {
    data,
    context,
  } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    SimilarPagesBlock.dataSchema,
  );

  const { similarPagesBlockTags: tags } = elementData;

  const title = omitBy(elementData.title, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const pages = tags.data.flatMap((tag) => tag.attributes.similarPagesBlockPages.data
    .filter((item) => item.id !== context.page.id));

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        <Box className={classes.titleContainer} mb={5}>
          <TypographyElement
            className={classes.title}
            data={title}
          />
        </Box>
        {pages.map((item) => (
          <Box key={item.id} mb={5}>
            <PagePreviewItem item={item} />
          </Box>
        ))}
      </Container>
    </div>
  );
}

function PagePreviewItem({ item }) {
  const classes = useStyles();
  const { url } = item.attributes;

  return (
    <Paper className={classes.root} elevation={3}>
      <Link href={url}>
        <CardActionArea>
          <Grid alignItems="stretch" container>
            {item.attributes.blogPreviewMedia && (
              <Grid className={classes.mediaContainer} item sm={3} xs={12}>
                <MediaElement
                  className={classes.previewImage}
                  data={item.attributes.blogPreviewMedia}
                  isFluid
                />
                {item.attributes.blogPreviewMediaRibbon && (
                  <RibbonElement data={item.attributes.blogPreviewMediaRibbon} />
                )}
              </Grid>
            )}
            <Grid className={classes.textContainer} item>
              <Typography component="h4" variant="h5">
                {item.attributes.name}
              </Typography>
              <Box my={3}>
                {item.attributes.blogPreviewText && (
                  <TypographyElement data={item.attributes.blogPreviewText} />
                )}
              </Box>
            </Grid>
          </Grid>
        </CardActionArea>
      </Link>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    paddingTop: theme.spacing(10),
  },
  root: {
    '& *': {
      textDecoration: 'none !important',
    },
  },
  mediaContainer: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  previewImage: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    [theme.breakpoints.up('sm')]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  pageItemContainer: {
    display: 'flex',
  },
  pageItemCover: {
    width: 300,
  },
  pageItemTitle: {
    color: theme.palette.text.hint,
  },
}));

SimilarPagesBlock.typeName = 'ComponentContentSimilarPages'; // Strapi element type
SimilarPagesBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
  }).isRequired,
  context: PropTypes.object,
};
SimilarPagesBlock.defaultProps = {
  context: {},
};
SimilarPagesBlock.dataSchema = yup.object()
  .shape({
    title: TypographyElement.dataSchema,
  });
SimilarPagesBlock.graphQlSchema = `
... on ${SimilarPagesBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  similarPagesBlockTags: tags {
    data {
      id
      attributes {
        name
        similarPagesBlockPages: pages(sort: "name:asc", filters: { locale: { eq: $localeStr } }) {
          data {
            id
            attributes {
              name
              url
              slug
              pageType
              publishedAt
              publishDate
              blogPreviewText {
                ${TypographyElement.graphQlSchema}
              }
              blogPreviewMedia {
                ${MediaElement.graphQlSchema}
              }
              blogPreviewMediaRibbon {
                ${RibbonElement.graphQlSchema}
              }
            }
          }
        }
      }
    }
  }
}
`;
