import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  Typography,
} from '@material-ui/core';
import { FiCheck } from 'react-icons/fi';
import { makeStyles } from '@material-ui/styles';

export default function OptionList(props) {
  const {
    name,
    disabled,
    value,
    label,
    error,
    isMultiple,
    options,
    handleChange,
    setFieldTouched,
  } = props;

  const classes = useStyles();

  let columnSpacing = 6;
  if (options.length > 2 && options.length < 4) columnSpacing = 4;
  else if (options.length >= 4) columnSpacing = 3;

  const handleToggleItem = (newValue) => () => {
    if (disabled) {
      return;
    }

    setFieldTouched(name);

    if (isMultiple) {
      const fieldValues = value ?? [];

      if (fieldValues.includes(newValue)) {
        handleChange(fieldValues.filter((v) => v !== newValue));
      } else {
        handleChange([...fieldValues, newValue]);
      }
    } else {
      handleChange(newValue);
    }
  };

  return (
    <Box px={1}>
      {label && (
        <Typography variant="subtitle1">
          {label}
        </Typography>
      )}
      <Grid container spacing={3}>
        {options.map((item) => {
          const itemValue = item.value || item.label;
          const image = item.image?.data?.attributes?.formats?.medium?.url
                ?? item.image?.data?.attributes?.url;
          const isSelected = isMultiple
            ? (value ?? []).includes(itemValue)
            : value === itemValue;

          return (
            <Grid key={item.id} item xs={columnSpacing}>
              {image ? (
                <Box style={{ position: 'relative' }}>
                  <Card
                    className={classes.cardImage}
                    elevation={isSelected ? 20 : 1}
                  >
                    <CardActionArea
                      onClick={handleToggleItem(itemValue)}
                    >
                      <CardMedia
                        component="img"
                        src={
                          item.image?.data?.attributes?.formats?.medium?.url
                              ?? item.image?.data?.attributes?.url
                        }
                      />
                      <CardContent style={{ padding: 8 }}>
                        <Typography
                          className={classes.label}
                          textAlign="center"
                          variant="subtitle2"
                        >
                          {item.label}
                        </Typography>
                      </CardContent>
                      {/* Do not delete this <div /> */}
                      <div />
                      {item.badge && (
                        <Box
                          px={1}
                          py={0.25}
                        >
                          <Typography variant="caption">
                            {item.badge}
                          </Typography>
                        </Box>
                      )}
                    </CardActionArea>
                  </Card>
                  {isSelected && (
                    <Box
                      className={classes.isSelectedIcon}
                    >
                      <FiCheck />
                    </Box>
                  )}
                </Box>
              ) : (
                <FormControlLabel
                  control={isMultiple ? (
                    <Checkbox
                      checked={isSelected}
                      onChange={handleToggleItem(itemValue)}
                    />
                  ) : (
                    <Radio
                      checked={isSelected}
                      color="primary"
                      onChange={handleToggleItem(itemValue)}
                    />
                  )}
                  label={item.label}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
      {error && (
        <Box sx={{ mt: 2 }}>
          <FormHelperText error>
            {error}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  cardImage: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },

  isSelectedIcon: {
    position: 'absolute',
    display: 'flex',
    top: (24 / 2 - 4) * -1,
    right: (24 / 2 - 4) * -1,
    width: 24,
    height: 24,
    borderRadius: '50px',
    backgroundColor: 'primary.main',
    color: 'secondary.main',
    justifyContent: 'center',
    alignItems: 'center',
  },

  label: {
    display: 'flex', alignItems: 'center',
  },

}));
