import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {
  FiCheckCircle, FiChevronDown, FiPlusCircle, FiSettings,
} from 'react-icons/fi';
import { gql, useQuery } from '@apollo/client';

import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Services({
  t,
  data,
  isAdditionalServices,
  navigationDirection,
  setData,
  onGoNext,
}) {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const {
    error,
    data: categoryData,
    loading,
  } = useQuery(FETCH_SERVICES, {
    variables: {
      locale: i18n.language,
      filters: isAdditionalServices ? {
        workshop_services: {
          isCrossSellingService: { eq: true },
        },
      } : {},
    },
    onCompleted: (queryData) => {
      const categories = getCategories(data.calendarId, queryData);
      setData({
        ...data,
        categories: [...data.categories, ...categories],
      });
    },
  });
  const categories = getCategories(data.calendarId, categoryData);

  useEffect(() => {
    if (loading || navigationDirection === 'backward') {
      return;
    }

    if (categories.length === 0) {
      onGoNext();
    }
  }, [onGoNext, navigationDirection, loading, categories]);

  if (error) {
    throw error;
  }

  if (loading) {
    return (
      <Skeleton />
    );
  }

  const handleToggleService = (id) => () => {
    let newServices = [];

    if (data.selectedServices?.find((serviceId) => serviceId === id)) {
      newServices = data.selectedServices.filter((serviceId) => serviceId !== id);
    } else {
      newServices = [
        ...data.selectedServices ?? [],
        id,
      ];
    }

    setData({
      ...data,
      selectedServices: newServices,
      isAnyServiceDisallowedOnSaturday: Boolean(newServices
        .find((sid) => categories
          .find((c) => c.attributes.workshop_services.data
            .find((s) => s.id === sid && s.attributes.isDisallowedOnSaturday)))),
    });
  };

  const gridSm = categories.length === 1 ? 12 : 6;

  return (
    <div>
      <Box mb={3}>
        <Typography variant="h6">
          {t(`components.organisms.WorkShopAppointmentTool.steps.Services.${isAdditionalServices ? 'additionalServices.title' : 'question'}`)}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {categories.length === 0 && (
          <Box p={2}>
            <Typography color="textSecondary" style={{ opacity: 0.5 }} variant="body2">
              Keine Leistungen verfügbar.
            </Typography>
          </Box>
        )}
        {categories.map((category) => (
          <Grid key={category.id} item sm={gridSm} xs={12}>
            <ServiceGroup
              category={category}
              classes={classes}
              data={data}
              i18n={i18n}
              onlySingleCategory={categories.length === 1}
              onToggle={handleToggleService}
              services={category.attributes.workshop_services.data ?? []}
              t={t}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function ServiceGroup(props) {
  const {
    t,
    i18n,
    classes,
    data,
    category,
    services,
    onlySingleCategory,
    onToggle,
  } = props;

  const hasServicesSelected = services
    .find(({ id }) => data.selectedServices.includes(id));

  return (
    <Accordion defaultExpanded={hasServicesSelected || onlySingleCategory}>
      <AccordionSummary
        data-e2e-id="category"
        data-e2e-value={category.id}
        expandIcon={<FiChevronDown />}
      >
        <Box alignItems="center" display="flex">
          <Box mr={1}>
            {category.attributes.icon?.data ? (
              <Avatar
                imgProps={{
                  style: { objectFit: 'contain' },
                }}
                src={category.attributes.icon.data.attributes.formats?.small?.url
                  ?? category.attributes.icon.data.attributes.formats?.thumbnail?.url
                  ?? category.attributes.icon.data.attributes.url}
                style={{
                  width: 20,
                  height: 20,
                  opacity: 0.75,
                }}
                variant="square"
              />
            ) : (
              <FiSettings style={{ display: 'block' }} />
            )}
          </Box>
          <Box>
            <Typography variant="subtitle2">
              {category.attributes.name}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {category.attributes.description && (
          <Typography gutterBottom variant="body2">
            {category.attributes.description}
          </Typography>
        )}
        <List disablePadding style={{ flex: 1 }}>
          {services.map((service) => (
            <Service
              key={service.id}
              classes={classes}
              i18n={i18n}
              isSelected={data.selectedServices.includes(service.id)}
              onToggle={onToggle}
              service={service}
              t={t}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

function Service(props) {
  const {
    t,
    i18n,
    classes,
    service,
    isSelected,
    onToggle,
  } = props;

  const [isDescriptionVisible, setDescriptionVisibility] = useState(false);

  const toggleDescriptionVisibility = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDescriptionVisibility(!isDescriptionVisible);
  };

  const serviceMinPrice = service.attributes.details?.minPrice
    ?.toLocaleString(i18n.language, {
      style: 'currency',
      currency: 'EUR',
    });

  return (
    <ListItem
      button
      className={clsx(classes.service, {
        [classes.serviceSelected]: isSelected,
      })}
      onClick={onToggle(service.id)}
    >
      {service.attributes.icon?.data && (
        <ListItemAvatar>
          <Avatar
            imgProps={{
              style: { objectFit: 'contain' },
            }}
            src={service.attributes.icon.data.attributes.formats?.small?.url
              ?? service.attributes.icon.data.attributes.formats?.thumbnail?.url
              ?? service.attributes.icon.data.attributes.url}
            style={{
              opacity: 0.75,
            }}
            variant="square"
          />
        </ListItemAvatar>
      )}
      <Box flex={1}>
        <ListItemText
          className={classes.serviceText}
          primary={service.attributes.name}
          primaryTypographyProps={{
            variant: 'subtitle2',
          }}
          secondary={service.attributes.description}
        />
        {service.attributes.details && (
          <>
            <Collapse in={isDescriptionVisible} unmountOnExit>
              <ListItemText
                className={classes.serviceText}
                primaryTypographyProps={{
                  variant: 'subtitle2',
                }}
                secondary={service.attributes.details.description}
              />
            </Collapse>
            <Button
              className={classes.expandButton}
              endIcon={(
                <FiChevronDown
                  className={clsx(classes.descriptionIcon, {
                    [classes.descriptionIconExpanded]: isDescriptionVisible,
                  })}
                />
              )}
              onClick={toggleDescriptionVisibility}
              size="small"
              variant="outlined"
            >
              {t(`components.organisms.WorkShopAppointmentTool.${isDescriptionVisible ? 'collapse' : 'expand'}`)}
            </Button>
            {serviceMinPrice && (
              <>
                <Divider style={{
                  marginTop: '15px',
                  marginBottom: '12px',
                }}
                />
                <Typography style={{ textAlign: 'right' }} variant="subtitle2">
                  <strong>
                    {t('components.organisms.WorkShopAppointmentTool.steps.Services.from')}
                    {' '}
                    {' '}
                    {' '}
                    {serviceMinPrice}
                  </strong>
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
      <ListItemSecondaryAction>
        <IconButton
          data-e2e-id="serviceButton"
          data-e2e-value={service.id}
          onClick={onToggle(service.id)}
        >
          {isSelected ? (
            <FiCheckCircle className={classes.iconRemove} />
          ) : (
            <FiPlusCircle className={classes.iconAdd} />
          )}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  service: {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  serviceSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  iconAdd: {
    color: theme.palette.primary.main,
  },
  iconRemove: {
    color: '#4CAF50',
  },
  serviceText: {
    marginRight: theme.spacing(1),
    whiteSpace: 'pre-wrap',
  },
  descriptionIconExpanded: {
    transform: 'rotate(180deg)',
  },
  descriptionIcon: {
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.complex,
    }),
  },
  expandButton: {
    marginTop: theme.spacing(1),
  },
  accordionDetails: {
    flexDirection: 'column',
    alignItems: 'justify',
  },
}));

Services.validateStep = function (data) {
  return data.selectedServices?.length > 0;
};

function getCategories(calendarId, data) {
  return data?.workshopCategories.data
    .map((item) => ({
      ...item,
      attributes: {
        ...item.attributes,
        workshop_services: {
          data: item.attributes.workshop_services.data
            .filter((service) => service.attributes.workshop_calendars.data
              .find((b) => b.id === calendarId)),
        },
      },
    }))
    .filter((item) => item.attributes.workshop_services.data.length > 0)
    ?? [];
}

const FETCH_SERVICES = gql`
  query GetItems($locale: I18NLocaleCode!, $filters: WorkshopCategoryFiltersInput!) {
    workshopCategories(
      sort: ["index", "name"],
      locale: $locale,
      filters: $filters
    ) {
      data {
        id
        attributes {
          name
          description
          index
          icon {
            data {
              id
              attributes {
                name
                width
                height
                formats
                ext
                mime
                size
                url
              }
            }
          }
          workshop_services {
            data {
              id
              attributes {
                name
                description
                wtpId
                isCrossSellingService
                isDisallowedOnSaturday
                details {
                  description
                  minPrice
                }
                workshop_calendars {
                  data{
                    id
                  }
                }
                icon {
                  data {
                    id
                    attributes {
                      name
                      width
                      height
                      formats
                      ext
                      mime
                      size
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
