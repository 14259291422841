import * as yup from 'yup';

import { TypographyElement, useContentElement } from '@plugins/next-cms-core';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import { useConsent } from '@lib/gdpr';
import Container from '../atoms/Container';
import ConsentRequiredFallback from '../molecules/ConsentRequiredFallback';

export default function SoftgardenJobsBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const hasConsent = useConsent('softgarden');

  // eslint-disable-next-line no-unused-vars
  const { elementData } = useContentElement(
    data,
    SoftgardenJobsBlock.dataSchema,
  );

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        {title && (
          <Box mb={3}>
            <TypographyElement data={title} />
          </Box>
        )}
        {!hasConsent ? (
          <ConsentRequiredFallback />
        ) : (
          <iframe
            className={classes.frame}
            src="https://schimmel-automobile.softgarden.io/de/widgets/jobs"
            style={{ width: '100%', height: 1500, overflow: 'hidden' }}
            title="Softgarden"
          />
        )}

      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
  },
  frame: {
    border: 'none',
  },
}));

SoftgardenJobsBlock.typeName = 'ComponentContentSoftgardenJobs'; // Strapi element type
SoftgardenJobsBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propType,
  }).isRequired,
};
SoftgardenJobsBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema.nullable(),
});
SoftgardenJobsBlock.graphQlSchema = `
... on ${SoftgardenJobsBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
}
`;
