import {
  Box, Button, CardActionArea, Chip, Grid, Paper, Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import Link from '@components/atoms/Link';
import { FiArrowRight } from 'react-icons/fi';
import { makeStyles } from '@material-ui/styles';
import { MediaElement, RibbonElement, TypographyElement } from '@plugins/next-cms-core';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

export default function BlogPreviewItem(props) {
  const { item } = props;
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation();
  const isBlogItem = item.attributes.pageType === 'blogItem';

  // eslint-disable-next-line max-len
  const { url } = item.attributes;
  // const url = isBlogItem ? getBlogItemRoute(item.slug) : item.url;
  const date = DateTime.fromISO(item.attributes.publishDate ?? item.attributes.publishedAt);
  const isExpired = item.attributes.expirationDate
    && DateTime.fromISO(item.attributes.expirationDate) < DateTime.now();

  const handleGoToBlogItem = (path) => {
    router.push(path);
  };

  return (
    <Paper className={classes.root} elevation={3}>
      <CardActionArea onClick={() => handleGoToBlogItem(url)}>
        <Grid alignItems="stretch" container>
          {item.attributes.blogPreviewMedia && (
            <Grid className={classes.mediaContainer} item sm={6} xs={12}>
              <MediaElement
                className={classes.previewImage}
                data={item.attributes.blogPreviewMedia}
                isFluid
              />
              {item.attributes.blogPreviewMediaRibbon && (
                <RibbonElement data={item.attributes.blogPreviewMediaRibbon} />
              )}
              {item.attributes.expirationDate && (
                (!isExpired && !item.attributes.isPromotionExtended) ? null : (
                  <Box className={clsx(classes.ribbonBox, isExpired ? classes.isExpired : '')}>
                    <Typography
                      className={clsx(classes.ribbonText, isExpired ? classes.isExpiredText : '')}
                      variant="subtitle1"
                    >
                      {!isExpired && (item.attributes.isPromotionExtended && t('components.molecules.BlogPreviewItem.actionExtended'))}
                      {isExpired && t('components.molecules.BlogPreviewItem.actionExpired')}
                    </Typography>
                  </Box>
                )
              )}
            </Grid>
          )}
          <Grid className={classes.textContainer} item>
            <Box alignItems="center" display="flex">
              {!isBlogItem && (
                <Box mr={1}>
                  <Chip color="primary" label={t('components.molecules.BlogPreviewItem.action')} size="small" />
                </Box>
              )}
              <Typography color="primary" variant="body2">
                {date.toFormat('dd.MM.yyyy')}
              </Typography>
            </Box>
            <Typography component="h4" variant="h5">
              {item.attributes.name}
            </Typography>
            <Box my={3}>
              {item.attributes.blogPreviewText && (
                <TypographyElement
                  className={classes.blogPreviewText}
                  data={{
                    ...item.attributes.blogPreviewText,
                    value: `${item.attributes.blogPreviewText.value.length > 250
                      ? `${item.attributes.blogPreviewText.value.substring(0, 250)}...`
                      : item.attributes.blogPreviewText.value}`,
                  }}
                />
              )}
            </Box>
            <Box>
              <Link href={url} legacyBehavior>
                <Button
                  color="primary"
                  component="a"
                  endIcon={<FiArrowRight />}
                  variant="outlined"
                >
                  {t(`components.molecules.BlogPreviewItem.${isBlogItem ? 'readMore' : 'learnMore'}`)}
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </CardActionArea>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& *': {
      textDecoration: 'none !important',
    },
  },
  mediaContainer: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  previewImage: {
    flex: 1,
    aspectRatio: '16 / 9',
    objectFit: 'cover',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    [theme.breakpoints.up('sm')]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  blogItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
  },
  blogItemContainer: {
    display: 'flex',
  },
  blogItemCover: {
    width: 300,
  },
  blogItemTitle: {
    color: theme.palette.text.hint,
  },
  ribbonBox: {
    top: 0,
    right: 0,
    width: 0,
    height: 0,
    position: 'absolute',
    borderTop: `125px solid ${theme.palette.primary.main}`,
    borderLeft: '125px solid transparent',
    pointerEvents: 'none',
  },
  isExpired: {
    left: 0,
    right: 'unset',
    borderLeft: 'unset',
    borderRight: '125px solid transparent',
  },
  ribbonText: {
    position: 'absolute',
    color: 'white',
    textAlign: 'center',
    lineHeight: '1.1em',
    fontWeight: '900',
    transform: 'rotate(45deg) translateY(-15px) translateX(-128px)',
  },
  isExpiredText: {
    transform: 'rotate(-45deg) translateY(-65px) translateX(78px)',
  },
}));

BlogPreviewItem.propTypes = {
  // eslint-disable-next-line react/require-default-props
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      pageType: PropTypes.string.isRequired,
      publishedAt: PropTypes.string.isRequired,
      publishDate: PropTypes.string,
      blogPreviewText: PropTypes.shape(TypographyElement.propTypes),
      blogPreviewMedia: PropTypes.shape(MediaElement.propTypes),
    }),
  }),
};

BlogPreviewItem.graphQlSchema = `
  id
  attributes {
    name
    url
    slug
    pageType
    publishedAt
    publishDate
    isPinnedToTop
    expirationDate
    isPromotionExtended
    blogPreviewText {
      ${TypographyElement.graphQlSchema}
    }
    blogPreviewMedia {
      ${MediaElement.graphQlSchema}
    }
    blogPreviewMediaRibbon {
      ${RibbonElement.graphQlSchema}
    }
  }
`;
