import * as yup from 'yup';

import {
  Box, Button, Card, CardActionArea, CardActions, CardContent, Divider, Grid, Typography,
} from '@material-ui/core';
import {
  ColorScheme, LinkElement, MediaElement, TypographyElement, useContentElement,
} from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import Image from '../atoms/Image';
import Container from '../atoms/Container';
import { animationDefaults, easings, timings } from '../../theme';

export default function TilesBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    TilesBlock.dataSchema,
  );

  const {
    columns,
    tiles,
  } = elementData;
  let { colorScheme } = elementData;

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
    });
  }

  let columnSpacing = 0;
  const columnSpan = Math.floor(12 / columns);

  if (tiles.length < 3) {
    columnSpacing = 10;
  } else if (tiles.length < 5) {
    columnSpacing = 7;
  } else {
    columnSpacing = 3;
  }

  colorScheme = ColorScheme.getColorScheme(colorScheme, 'default');
  const cssColorClass = `${colorScheme[0].toUpperCase()}${colorScheme.substring(1)}`;

  return (
    <div className={clsx(classes.root, classes[`root${cssColorClass}`])}>
      <Box>
        <Container className={classes.container}>
          {title && (
            <Box mb={5}>
              <TypographyElement align="center" data={title} />
            </Box>
          )}
          <Grid
            alignItems="stretch"
            container
            justifyContent="center"
            spacing={columnSpacing}
          >
            {tiles.map((tile) => (
              <Grid
                key={tile.id}
                className={classes.tileContainer}
                item
                sm={columnSpan}
                xs={12}
              >
                <Tile classes={classes} item={tile} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

function Tile(props) {
  const { classes, item } = props;
  const markedItems = ['45', '46', '47', '48', '1077', '1078', '1079', '1080', '819', '820', '821', '822', '561', '562', '563', '564', '1293', '1294', '1295', '1296'];

  const {
    display,
    textOverMediaPosition,
    textOverMediaColor,
    bottomPriceBadge,
  } = item;

  const isMediaSvg = Boolean(item.media?.file.data.attributes.url.endsWith('.svg'));
  let text = null;
  let textOverMedia = null;

  if (item.text && item.text.value) {
    text = omitBy(item.text, isNull);
    defaults(text, {
      semanticVariant: 'div',
      displayVariant: 'body2',
      textAlign: 'justify',
    });
  }

  if (item.textOverMedia && item.textOverMedia.value) {
    textOverMedia = omitBy(item.textOverMedia, isNull);
    defaults(textOverMedia, {
      semanticVariant: 'div',
      displayVariant: 'body2',
      textAlign: 'justify',
    });
  }

  return (
    <Card
      className={clsx(classes.tile, {
        [classes.tileButton]: display === 'button',
      })}
      elevation={3}
    >
      <LinkElement data={item.link}>
        <CardActionArea
          className={classes.tileActionArea}
          component="a"
        >
          {display !== 'button' && (
            <>
              {item.media && (
                <div className={classes.tileMediaContainer}>
                  <MediaElement
                    className={classes.tileMedia}
                    data={item.media}
                    isFluid
                  />
                  {textOverMedia && (
                    <div className={clsx(
                      classes.tileMediaTextContainer,
                      classes[`tileMediaTextContainer_${textOverMediaPosition ?? 'top'}`],
                    )}
                    >
                      <TypographyElement
                        className={classes.tileMediaText}
                        color={textOverMediaColor ?? 'initial'}
                        data={textOverMedia}
                      />
                    </div>
                  )}
                </div>
              )}
              {(item.title || text || item.buttonLabel || bottomPriceBadge) && (
                <CardContent className={classes.tileContent}>
                  {(item.title || text) && (
                    <Box flex={1}>
                      {item.title && (
                        <Box mb={2}>
                          <Typography variant="subtitle1">
                            {item.title}
                          </Typography>
                        </Box>
                      )}
                      {text && (
                        <Box mb={3}>
                          <TypographyElement
                            data={text}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                  {item.buttonLabel && (
                    <Button
                      color="primary"
                      component="a"
                      fullWidth
                      variant="outlined"
                    >
                      {item.buttonLabel}
                    </Button>
                  )}
                </CardContent>
              )}
              {markedItems.includes(item.id) && (
                <Grid container spacing={4}>
                  <Grid item xs={1} />
                  <Grid item xs={2}>
                    <Image
                      alt="Hyundai"
                      className={clsx(
                        classes.carBrandLogo,
                        classes.carBrandLogoHyundai,
                      )}
                      isFluid
                      src="/images/car-brands/hyundai-motor-company-logo-2.svg"
                    />
                  </Grid>
                  <Grid className={classes.carBrandLogoItem} item xs={2}>
                    <Image
                      alt="MG Motor"
                      className={clsx(
                        classes.carBrandLogo,
                        classes.carBrandLogoMG,
                      )}
                      isFluid
                      src="/images/car-brands/mgmotors-logo.svg"
                    />
                  </Grid>
                  <Grid className={classes.carBrandLogoItem} item xs={2}>
                    <Image
                      alt="Mitsubishi"
                      className={clsx(
                        classes.carBrandLogo,
                        classes.carBrandLogoMitsubishi,
                      )}
                      isFluid
                      src="/images/car-brands/mitsubishi-logo.svg"
                    />
                  </Grid>
                  <Grid className={classes.carBrandLogoItem} item xs={2}>
                    <Image
                      alt="FUSO"
                      className={clsx(
                        classes.carBrandLogo,
                        classes.carBrandLogoFuso,
                      )}
                      isFluid
                      src="/images/car-brands/mitsubishi-fuso-logo.svg"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Image
                      alt="Maxus"
                      className={clsx(
                        classes.carBrandLogo,
                        classes.carBrandLogoMaxus,
                      )}
                      isFluid
                      src="/images/car-brands/maxus-logo.png"
                    />
                  </Grid>
                </Grid>
              )}
              {bottomPriceBadge && (
                <CardActions>
                  <Box flex={1} p={1}>
                    <Box mb={1}>
                      <Divider />
                    </Box>
                    <Typography
                      align="right"
                      color="primary"
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: bottomPriceBadge,
                      }}
                      variant="h4"
                    />
                  </Box>
                </CardActions>
              )}
            </>
          )}
          {display === 'button' && (item.media || item.buttonLabel) && (
            <CardContent
              className={clsx(
                classes.tileContent,
                classes.tileContentCentered,
              )}
            >
              {item.media && (
                <MediaElement
                  className={clsx(
                    classes.tileMedia,
                    classes.tileMediaSpacing,
                    isMediaSvg ? [
                      classes.tileMediaSvg,
                      classes[`tileMediaSvg_${item.media.scaling ?? 'normal'}`],
                    ] : null,
                  )}
                  data={item.media}
                  isFluid={!isMediaSvg}
                  isMediaSvg={isMediaSvg}
                />
              )}
              {item.buttonLabel && (
                <Typography align="center" color="secondary" variant="button">
                  {item.buttonLabel}
                </Typography>
              )}
            </CardContent>
          )}
        </CardActionArea>
      </LinkElement>
    </Card>
  );
}

Tile.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
};

Tile.defaultProps = {
  classes: {},
  item: null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  rootPrimary: {
    backgroundColor: theme.palette.secondary.main,
  },
  rootSecondary: {
    backgroundColor: theme.palette.primary.main,
  },
  rootTertiary: {
    backgroundColor: theme.palette.tertiary.main,
  },
  rootQuaternary: {
    backgroundColor: theme.palette.quaternary.main,
  },
  rootLight: {
    backgroundColor: theme.palette.light.main,
  },
  rootDark: {
    backgroundColor: theme.palette.dark.main,
  },
  rootMitsubishiYellow: {
    backgroundColor: '#f5ba45',
  },
  container: {},
  tileContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tile: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    transition: `all ${timings.slowAnimationTiming}ms ${easings.defaultEasingCss}`,
    '&:hover': {
      transform: `scale(${animationDefaults.defaultScale})`,
    },
  },
  tileMediaContainer: {
    position: 'relative',
  },
  tileMedia: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  tileMediaSpacing: {
    marginBottom: theme.spacing(2),
  },
  tileMediaSvg: {},
  tileMediaSvg_tiny: {
    width: 16,
    height: 16,
  },
  tileMediaSvg_small: {
    width: 22,
    height: 22,
  },
  tileMediaSvg_smaller: {
    width: 38,
    height: 38,
  },
  tileMediaSvg_normal: {
    width: 60,
    height: 60,
  },
  tileMediaSvg_larger: {
    width: 80,
    height: 80,
  },
  tileMediaSvg_large: {
    width: 120,
    height: 120,
  },
  tileMediaSvg_big: {
    width: 160,
    height: 160,
  },
  tileMediaTextContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: theme.spacing(1),
  },
  tileMediaTextContainer_top: {
    justifyContent: 'flex-start',
  },
  tileMediaTextContainer_center: {
    justifyContent: 'center',
  },
  tileMediaTextContainer_bottom: {
    justifyContent: 'flex-end',
  },
  tileButton: {
    backgroundColor: theme.palette.primary.main,
  },
  tileActionArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textDecoration: 'none !important',
  },
  tileContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  tileContentCentered: {
    justifyContent: 'center',
    alignItems: 'center',
    // aspectRatio: '3 / 2', Error in layout
    minHeight: 150,
  },
  carBrandLogo: {
    marginBottom: theme.spacing(2),
  },
  carBrandLogoItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  carBrandLogoHyundai: { width: '100%' },
  carBrandLogoMG: { width: 105 },
  carBrandLogoMitsubishi: { width: 105 },
  carBrandLogoFuso: { width: 105 },
  carBrandLogoMaxus: { width: '100%' },
}));

TilesBlock.typeName = 'ComponentContentTiles'; // Strapi element type
TilesBlock.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.number,
    title: TypographyElement.propTypes,
    tiles: PropTypes.arrayOf(PropTypes.shape({
      display: PropTypes.oneOf([null, 'button', 'card']),
      button_label: PropTypes.string,
      title: PropTypes.string,
      text: TypographyElement.propTypes,
      link: LinkElement.propTypes.isRequired,
      media: MediaElement.propTypes,
      text_over_media: TypographyElement.propTypes,
      textOverMediaPosition: PropTypes.string,
      textOverMediaColor: PropTypes.string,
      bottomPriceBadge: PropTypes.string,
    })),
    color_scheme: ColorScheme.propTypes,
  }).isRequired,
};

TilesBlock.dataSchema = yup.object().shape({
  columns: yup.number().nullable(),
  title: TypographyElement.dataSchema.nullable(),
  tiles: yup.array().of(yup.object().shape({
    display: yup.string().oneOf([null, 'button', 'card']).nullable(),
    button_label: yup.string().nullable(),
    title: yup.string().nullable(),
    text: TypographyElement.dataSchema.nullable(),
    link: LinkElement.dataSchema.required(),
    media: MediaElement.dataSchema.nullable(),
    text_over_media: TypographyElement.dataSchema.nullable(),
    textOverMediaPosition: yup.string().nullable(),
    textOverMediaColor: yup.string().nullable(),
    bottomPriceBadge: TypographyElement.dataSchema.nullable(),
  })),
  color_scheme: ColorScheme.dataSchema.nullable(),
});
TilesBlock.graphQlSchema = `
... on ${TilesBlock.typeName} {
  id
  columns
  title {
    ${TypographyElement.graphQlSchema}
  }
  tiles(pagination: {limit: 100}) {
    id
    display
    buttonLabel
    title
    text {
      ${TypographyElement.graphQlSchema}
    }
    link {
      ${LinkElement.graphQlSchema}
    }
    media {
      ${MediaElement.graphQlSchema}
    }
    textOverMedia {
      ${TypographyElement.graphQlSchema}
    }
    textOverMediaPosition
    textOverMediaColor
    bottomPriceBadge
  }
  colorScheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;
