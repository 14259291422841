import * as yup from 'yup';

import { Box, Grid } from '@material-ui/core';
import { ColorScheme, TypographyElement, useContentElement } from '@plugins/next-cms-core';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import Container from '../atoms/Container';

export default function TextBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    TextBlock.dataSchema,
  );

  const {
    textBlockTextColumns,
    colorScheme,
  } = elementData;

  const title = omitBy(elementData.textBlockTitle, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const isSingleColumn = textBlockTextColumns.length === 1;
  const columnSpread = Math.floor(12 / textBlockTextColumns.length);
  const textBlockColorScheme = ColorScheme.getColorScheme(colorScheme, 'initial');
  const cssColorClass = `${textBlockColorScheme[0].toUpperCase()}${textBlockColorScheme.substring(1)}`;

  return (
    <div className={clsx(classes.root, classes[`root${cssColorClass}`])}>
      <Container className={classes.container}>
        <Grid container justifyContent="center">
          <Grid item lg={isSingleColumn ? 9 : 12} md={isSingleColumn ? 10 : 12}>
            <Box className={classes.titleContainer} mb={5}>
              <TypographyElement
                className={classes.title}
                color={textBlockColorScheme}
                data={title}
              />
            </Box>
            <Grid
              className={clsx(
                classes.textContainer,
                { [classes.textContainerSingle]: isSingleColumn },
              )}
              container
              spacing={5}
            >
              {textBlockTextColumns.map((element) => {
                const text = omitBy(element, isNull);
                defaults(text, {
                  semanticVariant: 'div',
                  displayVariant: 'body1',
                  textAlign: 'justify',
                });

                return (
                  <Grid
                    key={element.id}
                    item
                    md={columnSpread}
                    xs={12}
                  >
                    <TypographyElement color={textBlockColorScheme} data={text} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  rootPrimary: {
    backgroundColor: theme.palette.secondary.main,
  },
  rootSecondary: {
    backgroundColor: theme.palette.primary.main,
  },
  rootTertiary: {
    backgroundColor: theme.palette.tertiary.main,
  },
  rootQuaternary: {
    backgroundColor: theme.palette.quaternary.main,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  titleContainer: {},
  textContainerSingle: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(9),
    },
  },
  title: {
    '& p': {
      margin: 0,
    },
  },
}));

TextBlock.typeName = 'ComponentContentText'; // Strapi element type
TextBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    textColumns: PropTypes.arrayOf(PropTypes.shape(TypographyElement.propTypes)),
    colorScheme: ColorScheme.propTypes,
  }).isRequired,
};
TextBlock.dataSchema = yup.object().shape({
  title: TypographyElement.dataSchema,
  textColumns: yup.array(TypographyElement.dataSchema),
  colorScheme: ColorScheme.dataSchema.nullable(),
});
TextBlock.graphQlSchema = `
... on ${TextBlock.typeName} {
  id
  textBlockTitle: title {
    ${TypographyElement.graphQlSchema}
  }
  textBlockTextColumns: text {
    ${TypographyElement.graphQlSchema}
  }
  colorScheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;
