import { Checkbox, FormControlLabel } from '@material-ui/core';
import { checkboxField } from '@lib/form';
import PropTypes from 'prop-types';

export default function CheckBoxField(props) {
  const {
    name,
    disabled,
    value,
    error,
    touched,
    label,
    helperText,
    handleChange,
    handleBlur,
    labelPlacement,
    readOnly,
  } = props;

  return (
    <FormControlLabel
      aria-readonly
      control={(
        <Checkbox
          checked={value}
          color="primary"
          disabled={disabled || readOnly}
          error={error && touched}
          name={name}
          onBlur={handleBlur}
          onChange={(event) => handleChange(event.target.checked)}
          size="small"
        />
      )}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
}

CheckBoxField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  labelPlacement: PropTypes.string,
  readOnly: PropTypes.bool,
};

CheckBoxField.defaultProps = {
  disabled: false,
  value: false,
  error: false,
  touched: false,
  label: '',
  helperText: '',
  labelPlacement: 'end',
  readOnly: false,
};

CheckBoxField.getDefaultValue = checkboxField.getDefaultValue;
CheckBoxField.serializeValue = checkboxField.serializeValue;
CheckBoxField.deserializeValue = checkboxField.deserializeValue;
CheckBoxField.getDisplayValue = checkboxField.getDisplayValue;
CheckBoxField.validationSchema = checkboxField.validationSchema;
CheckBoxField.graphQlSchema = `
  typeCheckbox {
    labelPlacement
  }
`;
