/* eslint-disable prefer-const,react/require-default-props */
import {
  Box, Button, FormControl, Grid, Hidden, InputLabel, MenuItem, Paper, Tab, Tabs,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { FiArrowRightCircle } from 'react-icons/fi';
import PropTypes from 'prop-types';
import cx from 'clsx';
import isNil from 'lodash/isNil';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import { useTranslation } from 'react-i18next';
import { FaCarSide, FaTruck, FaVanShuttle } from 'react-icons/fa6';
import Link from '../atoms/Link';
import Select from '../atoms/Select';
import { transformBrandTitle } from '../vehiclefleet/CarBrandTitle';

export default function CarSearchFilterBar(props) {
  const {
    translateY,
    preSelectedBrand,
    preSelectedBranch,
    isMobile,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [cachedTotalCount, setTotalCount] = useState(0);

  const { error, data, refetch } = useQuery(QUERY_CAR_SEARCH_FILTER_DATA, {
    variables: {
      brand: preSelectedBrand,
      branch: preSelectedBranch,
    },
  });

  if (error) {
    throw error;
  }

  let {
    // Filter data
    categories = [],
    brands = [],
    models = [],
    // Selection
    category = ['all'],
    brand,
    model,
    branch,
  } = data?.carSearchFilterData ?? {};
  const {
    totalCount,
  } = data?.carSearchResultCount ?? {
    totalCount: cachedTotalCount,
  };

  const searchQuery = new URLSearchParams(omitBy({
    category,
    brand,
    model,
    branch,
  }, isNil));
  const searchUrl = `/fahrzeugsuche?${searchQuery.toString()}`;

  useEffect(() => {
    setTotalCount(totalCount);
  }, [totalCount]);

  const handleChangeVehicleCategory = (useValue = true) => (e, value) => {
    const vehicleCategory = useValue ? value : e.target.value;
    refetch({
      category: vehicleCategory.split('*'),
      brand: null,
      model: null,
    });
  };
  const handleChangeVehicleBrand = (e) => {
    const vehicleBrand = e.target.value;
    refetch({
      category,
      brand: vehicleBrand,
      model: null,
    });
  };
  const handleChangeVehicleModel = (e) => {
    const vehicleModel = e.target.value;
    refetch({
      category,
      brand,
      model: vehicleModel,
    });
  };

  if (!categories || categories.length === 0) {
    categories = [
      'all',
      'alternative-drives',
      'compact',
      'sedan',
      'wagon',
      'suv',
      'van',
      'truck',
    ];
  }

  return (
    <Box
      className={cx(classes.root, { [classes.translate]: translateY })}
      my={2}
    >
      <Paper className={classes.container} elevation={10}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={12}>
            <VehicleCategory
              classes={classes}
              onChange={handleChangeVehicleCategory}
              t={t}
              vehicleCategories={categories}
              vehicleCategory={category}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={isMobile ? 6 : 12}>
            <Brand
              brand={brand}
              brands={brands}
              classes={classes}
              onChange={handleChangeVehicleBrand}
              t={t}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={isMobile ? 6 : 12}>
            <Model
              classes={classes}
              model={model}
              models={models}
              onChange={handleChangeVehicleModel}
              t={t}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Link href={searchUrl} legacyBehavior>
              <Button
                color="secondary"
                component="a"
                endIcon={<FiArrowRightCircle />}
                fullWidth
              >
                {t('components.organisms.CarSearchFilterBar.detailedSearch')}
              </Button>
            </Link>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Link href={searchUrl} legacyBehavior>
              <Button
                color="secondary"
                component="a"
                fullWidth
                variant="contained"
              >
                {totalCount}
                {' '}
                {t('components.organisms.CarSearchFilterBar.showVehicles')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

CarSearchFilterBar.propTypes = {
  translateY: PropTypes.bool,
  preSelectedBrand: PropTypes.string,
  preSelectedBranch: PropTypes.string,
};

function VehicleCategory(props) {
  const {
    t,
    vehicleCategories,
    vehicleCategory,
    onChange,
    classes,
  } = props;

  return (
    <>
      <Hidden smDown>
        <Box>
          <Tabs
            classes={{
              root: classes.tabsContainer,
              indicator: classes.tabsIndicator,
            }}
            onChange={onChange(true)}
            value={vehicleCategory.join('*') ?? 'all'}
            variant="scrollable"
          >
            {vehicleCategories.map((category) => (
              <Tab
                key={category}
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabWrapper,
                  selected: classes.tabSelected,
                  labelIcon: classes.tabLabelIcon,
                }}
                icon={(
                  <img
                    alt={t(`enums.vehicleCategory.${category || 'unknown'}`)}
                    src={`/images/icons/car-type-${category}-${vehicleCategory[0] === category ? 'primaryContrast' : 'primaryContrast'}.svg`}
                    style={mapVehicleIconSize(category)}
                  />
                )}
                label={t(`enums.vehicleCategory.${category || 'unknown'}`)}
                value={category ?? null}
              />
            ))}
          </Tabs>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box>
          <Tabs
            classes={{
              root: classes.tabsContainer2,
              indicator: classes.tabsIndicator,
            }}
            onChange={onChange(true)}
            value={vehicleCategory.join('*') ?? 'all'}
            variant="scrollable"
          >
            {VEHICLE_CATEGORIES.map((category) => (
              <Tab
                key={category.name}
                classes={{
                  root: classes.tabRoot2,
                  wrapper: classes.tabWrapper,
                  selected: classes.tabSelected,
                  labelIcon: classes.tabLabelIcon,
                }}
                icon={category.icon}
                value={category.value.join('*') ?? null}
              />
            ))}
          </Tabs>
        </Box>
      </Hidden>
    </>
  );
}

function Brand(props) {
  const {
    t,
    brands,
    brand,
    classes,
    onChange,
  } = props;

  return (
    <FormControl
      classes={{
        root: 'MuiFormControl-colorSecondary',
      }}
      className={classes.formElement}
      color="secondary"
      fullWidth
      variant="outlined"
    >
      <InputLabel id="brand-select">{t('components.organisms.CarSearchFilterBar.brand')}</InputLabel>
      <Select
        labelId="brand-select"
        onChange={onChange}
        value={brand ?? ''}
      >
        <MenuItem key="empty" value={null}>
          {t('components.organisms.CarSearchFilterBar.allBrands')}
        </MenuItem>
        {brands.map((item) => (
          <MenuItem key={item} value={item}>
            {transformBrandTitle(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function Model(props) {
  const {
    t,
    models,
    model,
    classes,
    onChange,
  } = props;

  return (
    <FormControl
      classes={{
        root: 'MuiFormControl-colorSecondary',
      }}
      className={classes.formElement}
      color="primary"
      fullWidth
      variant="outlined"
    >
      <InputLabel id="model-select">
        {t('components.organisms.CarSearchFilterBar.model')}
      </InputLabel>
      <Select
        labelId="model-select"
        onChange={onChange}
        value={model ?? ''}
      >
        <MenuItem key="empty" value={null}>
          {t('components.organisms.CarSearchFilterBar.allModels')}
        </MenuItem>
        {models.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

/*
function PriceRange({ classes }) {
  const [priceRange, setPriceRange] = useState([0, 50000]);

  return (
    <div className={classes.formElement}>
      <Typography variant="caption" color="secondary">Preis</Typography>
      <Box display="flex" alignItems="center">
        <Box mr={2} width={50} flexShrink={0}>
          <Typography noWrap variant="caption" color="secondary">
            {`${formatNumber(priceRange[0])} €`}
          </Typography>
        </Box>
        <Slider
          min={0}
          max={50000}
          step={1000}
          value={priceRange}
          onChange={(e, value) => setPriceRange(value)}
          valueLabelDisplay="off"
          valueLabelFormat={(x) => `${x} €`}
          color="secondary"
        />
        <Box ml={2} width={50} flexShrink={0}>
          <Typography noWrap variant="caption" color="secondary">
            {`${formatNumber(priceRange[1])} €`}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

function formatNumber(value){
  return value.toLocaleString('en-US').replace(',', '.');
}
*/

const VEHICLE_CATEGORIES = [
  {
    name: 'cars',
    icon: <FaCarSide size={30} />,
    value: ['compact', 'sedan', 'wagon', 'suv'],
  },
  {
    name: 'vans',
    icon: <FaVanShuttle size={30} />,
    value: ['van', 'bus'],
  },
  {
    name: 'trucks',
    icon: <FaTruck size={30} />,
    value: ['truck'],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    position: 'relative',
    flex: 1,
    zIndex: 1,
  },
  translate: {
    marginTop: '-85px',
  },
  container: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formElement: {
  },
  tabsContainer: {

  },
  tabsContainer2: {
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,0.70)',
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    '& .MuiTabs-flexContainer': {
      height: '100%',
    },
  },
  tabsIndicator: {
    display: 'none',
  },
  tabRoot: {
    flex: 1,
    minWidth: 0,
    minHeight: 0,
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    borderWidth: 2,
    borderColor: 'transparent',
    borderStyle: 'solid',
  },
  tabRoot2: {
    flex: 1,
    minWidth: 0,
    minHeight: 0,
    padding: theme.spacing(0.5),
    borderRight: 2,
    borderRightColor: 'white',
    borderRightStyle: 'solid',
    '&:last-child': {
      borderRight: 0,
    },
  },
  tabSelected: {
    borderColor: theme.palette.secondary.main,
    backgroundColor: 'rgba(255,255,255,0.15)',
    '& $tabWrapper': {
      color: theme.palette.secondary.main,
    },
  },
  tabWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    '& > img:first-child': {
      margin: `0 0 ${theme.spacing(0)}px 0 !important`,
    },
    '& > img path': {
      fill: 'red',
    },
  },
  [theme.breakpoints.up('md')]: {
    form: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    formElement: {
    },
  },
}));

function mapVehicleIconSize(value) {
  const factor = 0.85;

  switch (value) {
    case 'all':
      return { width: 100 * factor, height: 50 * factor };
    case 'compact':
      return { width: 50 * factor, height: 40 * factor };
    case 'alternative-drives':
      return { width: 100 * factor, height: 50 * factor };
    case 'sedan':
      return { width: 100 * factor, height: 50 * factor };
    case 'wagon':
      return { width: 90 * factor, height: 50 * factor };
    case 'suv':
      return { width: 90 * factor, height: 56 * factor };
    case 'van':
      return { width: 100 * factor, height: 50 * factor };
    default:
      return null;
  }
}

export const QUERY_CAR_SEARCH_FILTER_DATA = gql`
query GetSearchFilterData($category: [String], $brand: String, $model: String, $branch: ID) {
  carSearchFilterData(category: $category, brand: $brand, model: $model, branch: $branch) {
    categories
    category
    brands
    brand
    models
    model
    branch
  }
  carSearchResultCount(category: $category, brand: $brand, model: $model, branch: $branch) {
    totalCount
  }
}
`;
