/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';

import {
  Box, Grid, Paper, Typography,
} from '@material-ui/core';
import {
  ButtonElement, MediaElement, TypographyElement, useContentElement,
} from '@plugins/next-cms-core';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Virtual } from 'swiper';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import { useState } from 'react';
import Container from '../atoms/Container';

SwiperCore.use([Virtual]);
SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);

export default function TextMediaBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    TextMediaBlock.dataSchema,
  );

  const variant = elementData.variant ?? 'Plain';
  const order = elementData.order ?? 'TextMedia';

  const items = [];
  const imageElement = {
    classes: [classes.mediaContainer],
    element: <ImageBlock classes={classes} data={elementData} variant={variant} />,
  };
  const textElement = {
    classes: [classes.textContainer],
    element: <TextBlock classes={classes} data={elementData} variant={variant} />,
  };
  const rootClasses = [classes.root];

  switch (order) {
    case 'MediaText':
      items.push(imageElement);
      items.push(textElement);
      rootClasses.push(classes.orderImageText);
      break;
    default:
      items.push(textElement);
      items.push(imageElement);
      rootClasses.push(classes.orderTextImage);
      break;
  }

  const columnSpan = variant === 'Card' ? 6 : 6;
  const columnSpacing = variant === 'Card' ? 0 : 10;

  if (variant === 'Card') {
    rootClasses.push(classes.variantCard);
  }

  return (
    <div className={clsx(rootClasses)}>
      <Container className={classes.container}>
        <Box my={10}>
          <Grid
            alignItems="center"
            container
            spacing={columnSpacing}
          >
            {items.map((item, index) => (
              <Grid
                key={index}
                className={clsx(item.classes)}
                item
                md={columnSpan}
                xs={12}
              >
                {item.element}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

function ImageBlock(props) {
  const { classes, data, variant } = props;
  const {
    media,
  } = data;
  const [swiper, setSwiper] = useState();

  if (media.length === 0) {
    return null;
  }

  if (media.length === 1) {
    const firstMedia = media[0];

    return (
      <Box
        alignItems="center"
        className={classes.mediaItem}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <MediaElement data={firstMedia} />
        {firstMedia.caption && (
          <div className={classes.mediaLabel}>
            <Typography variant="caption">
              {firstMedia.caption}
            </Typography>
          </div>
        )}
      </Box>
    );
  }

  return (
    <Swiper
      autoHeight
      autoplay={{
        delay: 5000,
      }}
      className={classes.swipeContainer}
      onSwiper={(newValue) => setSwiper(newValue)}
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      slidesPerView={1}
    >
      {media.map((mediaItem) => (
        <SwiperSlide
          key={mediaItem.id}
          className={classes.swipeSlideItem}
        >
          <MediaElement data={mediaItem} />
          <div className={classes.mediaLabel}>
            <Typography variant="caption">
              {mediaItem.caption}
            </Typography>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

function TextBlock(props) {
  const { variant, data, classes } = props;
  const { buttons } = data;

  const title = omitBy(data.textMediatitle, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const subtitle = omitBy(data.textMediaSubtitle, isNull);
  defaults(subtitle, {
    semanticVariant: 'div',
    displayVariant: 'body2',
  });

  const text = omitBy(data.textMediaText, isNull);
  defaults(text, {
    semanticVariant: 'div',
    displayVariant: 'body1',
  });

  const content = (
    <>
      {subtitle?.value && (
        <Box mb={2}>
          <TypographyElement
            className={classes.textSubTitle}
            color="primary"
            data={subtitle}
          />
        </Box>
      )}
      {title?.value && (
        <Box mb={3}>
          <TypographyElement
            className={classes.textTitle}
            data={title}
          />
        </Box>
      )}
      {text?.value && (
        <Box>
          <TypographyElement data={text} />
          {buttons && (
            <Box mt={5}>
              <Grid container spacing={2}>
                {buttons.map((button) => (
                  <Grid key={button.id} item>
                    <ButtonElement
                      color="primary"
                      component="a"
                      data={button}
                      variant="contained"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </>
  );

  if (variant === 'Card') {
    return (
      <Paper elevation={3}>
        <Box p={5}>
          {content}
        </Box>
      </Paper>
    );
  }

  return content;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  container: {},
  textContainer: {
    zIndex: 1,
  },
  swipeContainer: {
    paddingBottom: theme.spacing(3),
  },
  mediaContainer: {
    zIndex: 0,
  },
  mediaItem: {
    // position: 'relative',
  },
  mediaLabel: {
    flex: 1,
    marginTop: theme.spacing(0.5),
    // position: 'absolute',
    // bottom: `calc(${(theme.typography.caption.lineHeight) * -1}em - ${theme.spacing(0.5)}px)`,
  },
  mediaTypeVideo: {
    display: 'block',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    height: 'auto',
  },
  orderImageText: {
    '& $mediaContainer': {
      transformOrigin: 'left center',
    },
    '& $mediaLabel': {
      left: theme.shape.borderRadius,
    },
  },
  orderTextImage: {
    '& $mediaContainer': {
      transformOrigin: 'right center',
    },
    '& $mediaLabel': {},
  },
  variantCard: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    '& $mediaContainer': {
      transform: 'scale(1.2)',
    },
    '& $mediaLabel': {
      maxWidth: '70%',
    },
  },
  textTitle: {
    '& p': {
      marginTop: 0,
    },
  },
  textSubTitle: {
    '& p': {
      margin: 0,
    },
  },
}));

const PROPTYPE_VARIANT = PropTypes.oneOf(['Plain', 'Card']);

TextMediaBlock.typeName = 'ComponentContentTextMedia'; // Strapi element type
TextMediaBlock.propTypes = {
  data: PropTypes.shape({
    variant: PROPTYPE_VARIANT,
    order: PropTypes.oneOf(['TextMedia', 'MediaText']),
    media: PropTypes.arrayOf(PropTypes.shape(MediaElement.propTypes)),
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonElement.propTypes)),
  }).isRequired,
};
TextMediaBlock.dataSchema = yup.object().shape({
  variant: yup.string().nullable().oneOf([null, 'Plain', 'Card']),
  order: yup.string().nullable().oneOf([null, 'TextMedia', 'MediaText']),
  media: yup.array(MediaElement.dataSchema).nullable(),
  title: TypographyElement.dataSchema,
  subtitle: TypographyElement.dataSchema,
  text: TypographyElement.dataSchema,
  buttons: yup.array(ButtonElement.dataSchema).nullable(),
});
TextMediaBlock.graphQlSchema = `
... on ${TextMediaBlock.typeName} {
  id
  variant
  order
  media {
    ${MediaElement.graphQlSchema}
  }
  textMediatitle: title {
    ${TypographyElement.graphQlSchema}
  }
  textMediaSubtitle: subtitle {
    ${TypographyElement.graphQlSchema}
  }
  textMediaText: text {
    ${TypographyElement.graphQlSchema}
  }
  buttons {
    ${ButtonElement.graphQlSchema}
  }
}
`;

TextBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    variant: PROPTYPE_VARIANT,
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
  }).isRequired,
};
ImageBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PROPTYPE_VARIANT,
  data: PropTypes.shape({
    media: PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.oneOf(['image', 'video']),
    }),
  }).isRequired,
};

ImageBlock.defaultProps = {
  variant: 'Card',
};
