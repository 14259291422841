/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';

import {
  Box, Button, Fade, Grid, IconButton, Typography,
} from '@material-ui/core';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useEffect, useRef, useState } from 'react';

import { FiArrowLeft } from 'react-icons/fi';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import Container from '../atoms/Container';

export default function FaqBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [isIndexVisible, setIndexVisible] = useState(true);
  const [selectdItem, setSelectedItem] = useState(null);
  const [minHeight, setMinHeight] = useState(100);
  const rootRef = useRef();

  useEffect(() => {
    if (!rootRef.current) {
      return;
    }

    setMinHeight(rootRef.current.clientHeight);
  }, []);

  const { elementData } = useContentElement(
    data,
    FaqBlock.dataSchema,
  );
  const fadeDuration = theme.transitions.duration.enteringScreen;

  const { faqTitle: title, faqs } = elementData;
  const items = faqs.data.flatMap((f) => f.attributes.items);
  const selectedItem = selectdItem ? items.find((x) => x.id === selectdItem) : null;

  const handleSelectItem = (id) => () => {
    setIndexVisible(false);
    setTimeout(() => {
      setSelectedItem(id);
    }, fadeDuration);
  };
  const handleGoBack = () => {
    setSelectedItem(null);
    setTimeout(() => setIndexVisible(true), fadeDuration);
  };

  return (
    <div ref={rootRef} className={classes.root} style={{ minHeight }}>
      <Container>
        <Box mb={5}>
          <Typography align="center" variant="h3">
            {!isNil(title) && !isEmpty(title.trim())
              ? title.trim()
              : 'Sie haben Fragen - wir haben Antworten'}
          </Typography>
        </Box>
        <Fade
          in={isIndexVisible}
          mountOnEnter
          unmountOnExit
        >
          <Grid container spacing={3}>
            {items.map((item, index) => (
              <Grid
                key={index}
                item
                md={6}
                xs={12}
              >
                <Button
                  className={classes.questionButton}
                  color="secondary"
                  fullWidth
                  onClick={handleSelectItem(item.id)}
                  size="large"
                  variant="contained"
                >
                  {item.question}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Fade>
        <Fade
          in={selectdItem}
        >
          <div>
            <Box alignItems="center" display="flex" mb={3}>
              <Box mr={3}>
                <IconButton
                  onClick={handleGoBack}
                >
                  <FiArrowLeft />
                </IconButton>
              </Box>
              <Typography variant="h5">
                {selectedItem?.question}
              </Typography>
            </Box>
            <TypographyElement data={selectedItem?.answer} />
          </div>
        </Fade>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.text.hint,
  },
  questionButton: {
    borderRadius: theme.shape.borderRadius,
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      textAlign: 'left',
      whiteSpace: 'normal',
      wordWrap: 'break-word',
    },
  },
}));

FaqBlock.typeName = 'ComponentContentFaq'; // Strapi element type
FaqBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    faqs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        question: PropTypes.string.isRequired,
        answer: TypographyElement.propTypes,
      })),
    })),
  }).isRequired,
};

FaqBlock.dataSchema = yup.object().shape({
  title: yup.string().nullable(),
  faqs: yup.object().shape({
    data: yup.array().of(yup.object().shape({
      id: yup.number().required(),
      attributes: yup.object().shape({
        items: yup.array().of(yup.object().shape({
          question: yup.string().required(),
          answer: TypographyElement.dataSchema.required(),
        })).nullable(),
      }),
    })),
  }),
});
FaqBlock.graphQlSchema = `
... on ${FaqBlock.typeName} {
  id
  faqTitle: title
  faqs {
    data {
      id
      attributes {
        items {
          id
          question
          answer {
            ${TypographyElement.graphQlSchema}
          }
        }
      }
    }
  }
}
`;
