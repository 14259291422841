import { textField } from '@lib/form';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import TextInput from '../../../atoms/TextInput';

export default function TextField(props) {
  const {
    name,
    disabled,
    value,
    error,
    touched,
    label,
    helperText,
    isMultiline,
    startAdornment,
    endAdornment,
    handleChange,
    handleBlur,
    readOnly,
  } = props;

  return (
    <TextInput
      disabled={disabled}
      error={error && touched}
      fullWidth
      helperText={error || helperText}
      InputProps={{
        startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
        readOnly,
      }}
      label={label}
      minRows={isMultiline ? 3 : null}
      multiline={isMultiline}
      name={name}
      onBlur={handleBlur}
      onChange={(event) => handleChange(event.target.value)}
      size="small"
      value={value}
    />
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  isMultiline: PropTypes.bool,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

TextField.defaultProps = {
  disabled: false,
  value: '',
  error: false,
  touched: false,
  label: '',
  helperText: '',
  isMultiline: false,
  startAdornment: '',
  endAdornment: '',
  readOnly: false,
};

TextField.getDefaultValue = textField.getDefaultValue;
TextField.serializeValue = textField.serializeValue;
TextField.deserializeValue = textField.deserializeValue;
TextField.getDisplayValue = textField.getDisplayValue;
TextField.validationSchema = textField.validationSchema;
TextField.graphQlSchema = `
typeText {
  id
  isMultiline
  startAdornment
  endAdornment
  preFilledValue
}
`;
