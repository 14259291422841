import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

/**
 * https://www.gesetze-im-internet.de/pkw-envkv/BJNR103700004.html
 * https://www.gesetze-im-internet.de/normengrafiken/bgbl1_2011/j1756-1_0020.pdf
 */

export default function EmissionLabel(props) {
  const { efficiency } = props;
  const classes = useStyles();

  return (
    <span
      className={clsx(
        classes.root,
        classes[`efficiency${efficiency.replaceAll('+', 'Plus')}`],
      )}
    >
      <span>
        {efficiency}
      </span>
    </span>
  );
}

EmissionLabel.propTypes = {
  efficiency: PropTypes.oneOf([
    'A++++',
    'A+++',
    'A++',
    'A+',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
  ]).isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    color: 'white',
    height: 18,
    lineHeight: '18px',
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    // Triangle
    '&::after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      width: 0,
      height: 0,
      right: -9,
      borderStyle: 'solid',
      borderWidth: '9px 0 9px 9px',
      borderColor: 'transparent transparent transparent white',
    },
  },
  efficiencyAPlusPlusPlusPlus: {
    backgroundColor: '#00a54f',
    '&::after': {
      borderColor: 'transparent transparent transparent #00a54f',
    },
  },
  efficiencyAPlusPlusPlus: {
    backgroundColor: '#00a54f',
    '&::after': {
      borderColor: 'transparent transparent transparent #00a54f',
    },
  },
  efficiencyAPlusPlus: {
    backgroundColor: '#00a54f',
    '&::after': {
      borderColor: 'transparent transparent transparent #00a54f',
    },
  },
  efficiencyAPlus: {
    backgroundColor: '#00a54f',
    '&::after': {
      borderColor: 'transparent transparent transparent #00a54f',
    },
  },
  efficiencyA: {
    backgroundColor: '#00a54f',
    '&::after': {
      borderColor: 'transparent transparent transparent #00a54f',
    },
  },
  efficiencyB: {
    backgroundColor: '#4cb848',
    '&::after': {
      borderColor: 'transparent transparent transparent #4cb848',
    },
  },
  efficiencyC: {
    backgroundColor: '#bed630',
    '&::after': {
      borderColor: 'transparent transparent transparent #bed630',
    },
  },
  efficiencyD: {
    backgroundColor: '#fff101',
    '&::after': {
      borderColor: 'transparent transparent transparent #fff101',
    },
  },
  efficiencyE: {
    backgroundColor: '#fcb814',
    '&::after': {
      borderColor: 'transparent transparent transparent #fcb814',
    },
  },
  efficiencyF: {
    backgroundColor: '#f36e21',
    '&::after': {
      borderColor: 'transparent transparent transparent #f36e21',
    },
  },
  efficiencyG: {
    backgroundColor: '#ee1d23',
    '&::after': {
      borderColor: 'transparent transparent transparent #ee1d23',
    },
  },
}));
