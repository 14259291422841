import {
  Avatar, Box, Card, CardActionArea, CardContent, Grid, Typography,
} from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

export default function Calendar({
  t, data, setData, onGoNext,
}) {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const { error, data: calendarData, loading } = useQuery(FETCH_CALENDARS, {
    variables: {
      branchId: data.branch.data?.id ?? data.branch.id,
      locale: i18n.language,
    },
  });

  useEffect(() => {
    if (!data.calendarCount && calendarData && calendarData.workshopCalendars.data.length === 1) {
      handleSelectCalendar(calendarData.workshopCalendars.data[0].id)();
    }
  }, [calendarData]);

  if (error) {
    throw error;
  }

  if (loading) {
    return (
      <Skeleton />
    );
  }

  const calendars = calendarData?.workshopCalendars.data ?? [];

  const handleSelectCalendar = (id) => () => {
    const calendar = calendars.find((c) => c.id === id);

    setData({
      ...data,
      calendarCount: calendars.length,
      calendarId: id,
      calendar,
      categories: [],
      selectedServices: [],
      selectedDate: null,
      selectedTime: null,
    });
    onGoNext();
  };

  return (
    <div>
      <Box mb={3}>
        <Typography variant="h6">
          {t('components.organisms.WorkShopAppointmentTool.steps.Calendar.question')}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {calendars.map((calendar) => (
          <Grid key={calendar.id} item sm={6} xs={12}>
            <Item
              calendar={calendar}
              classes={classes}
              onSelect={handleSelectCalendar}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function Item(props) {
  const {
    calendar,
    onSelect,
  } = props;

  return (
    <Card>
      <CardActionArea
        data-e2e-id="calendar"
        data-e2e-value={calendar.id}
        onClick={onSelect(calendar.id)}
      >
        <CardContent>
          <Box alignItems="center" display="flex">
            {calendar.attributes.icon && (
              <Box mr={2}>
                <Avatar
                  imgProps={{
                    style: { objectFit: 'contain' },
                  }}
                  src={calendar.attributes.icon?.data?.attributes.formats?.small?.url
                    ?? calendar.attributes.icon?.data?.attributes.formats?.thumbnail?.url
                      ?? calendar.attributes.icon?.data?.attributes.url}
                  variant="square"
                />
              </Box>
            )}
            <Typography variant="subtitle1">
              {calendar.attributes.name}
            </Typography>
          </Box>
          {calendar.attributes.description && (
            <Box mt={1}>
              <Typography variant="body2">
                {calendar.attributes.description}
              </Typography>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  service: {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  serviceSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  iconAdd: {
    color: theme.palette.primary.main,
  },
  iconRemove: {
    color: '#4CAF50',
  },
}));

Calendar.validateStep = function (data) {
  return Boolean(data.calendarId);
};

const FETCH_CALENDARS = gql`
query GetItems($branchId: ID!, $locale: I18NLocaleCode!) {
  workshopCalendars(
    sort: ["index", "name"],
    filters: { branch: { id: { eq: $branchId } } },
    locale: $locale
  ) {
    data {
      id
      attributes {
        name
        description
        icon {
          data {
            id
            attributes {
              name
              formats
              url
            }
          }
        }
      }
    }
  }
}
`;
