import {
  Box, Card, CardContent, Collapse, Grid, IconButton, Tooltip, Typography,
} from '@material-ui/core';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { helper } from '@lib/form';
import { useRouter } from 'next/router';
import {
  memo, useCallback, useEffect, useState,
} from 'react';
import FormField from './FormField';
import { layoutWidth } from '../../../constants';

export default function FormFieldGroup(props) {
  const {
    title,
    fields,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    formDisplayState,
    isCollapsed,
  } = props;
  const router = useRouter();
  const classes = useStyles();
  const [isGroupCollapsed, setIsGroupCollapsed] = useState(isCollapsed);

  useEffect(() => {
    if (Object.keys(router.query).length > 1) {
      setIsGroupCollapsed(false);
    }
  }, [router.query]);

  const handleToggle = useCallback(() => {
    setIsGroupCollapsed(!isGroupCollapsed);
  }, [isGroupCollapsed]);

  const visibleFields = fields.filter((field) => formDisplayState[`field_${field.id}`].isVisible);

  if (visibleFields.length === 0) {
    return null;
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Title
          classes={classes}
          isCollapsed={isGroupCollapsed}
          onToggle={handleToggle}
          value={title}
        />
        <Collapse in={!isGroupCollapsed}>
          <Grid
            alignItems="flex-end"
            className={classes.innerCollapse}
            container
            spacing={3}
          >
            {visibleFields.filter((field) => !field.isHidden).map((field) => (
              <Grid
                key={field.id}
                item
                md={layoutWidth[field.layout?.width] ?? 12}
                xs={12}
              >
                <FormField
                  {...field}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  id={`field_${field.id}`}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  type={helper.getFieldType(field)}
                  values={values}
                />
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  );
}

FormFieldGroup.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  formDisplayState: PropTypes.object,
  isCollapsed: PropTypes.bool,
};

FormFieldGroup.defaultProps = {
  title: '',
  fields: [],
  values: {},
  errors: {},
  touched: {},
  handleBlur: () => {},
  handleChange: () => {},
  setFieldValue: () => {},
  setFieldTouched: () => {},
  formDisplayState: {},
  isCollapsed: false,
};

function TitleComponent(props) {
  const {
    isCollapsed,
    value,
    onToggle,
    classes,
  } = props;

  return (
    <Tooltip
      placement="top"
      title={isCollapsed ? 'Ausklappen' : 'Einklappen'}
    >
      <Box
        alignItems="center"
        className={classes.title}
        display="flex"
        onClick={onToggle}
        style={{ cursor: 'pointer' }}
      >
        <IconButton
          className={classes.collapseButton}
          size="small"
        >
          {isCollapsed ? <FiArrowDown /> : <FiArrowUp />}
        </IconButton>
        <Typography
          component="div"
          variant="subtitle1"
        >
          {value}
        </Typography>
      </Box>
    </Tooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  innerCollapse: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  collapseButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

TitleComponent.propTypes = {
  isCollapsed: PropTypes.bool,
  value: PropTypes.string,
  onToggle: PropTypes.func,
  classes: PropTypes.object,
};

TitleComponent.defaultProps = {
  isCollapsed: false,
  value: '',
  onToggle: () => {},
  classes: {},
};

const Title = memo(TitleComponent);
