/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';

import {
  Box,
  Grid,
  Avatar,
  Hidden,
  Divider,
  Typography,
} from '@material-ui/core';
import { ColorScheme, useContentElement } from '@plugins/next-cms-core';
import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import trim from 'lodash/trim';
import { FaStar } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import Button from '../atoms/Button';
import Container from '../atoms/Container';
import Image from '../atoms/Image';
import Link from '../atoms/Link';

export default function CustomerTestimonialsBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { elementData } = useContentElement(
    data,
    CustomerTestimonialsBlock.dataSchema,
  );

  let {
    colorScheme,
  } = elementData;
  const tags = (elementData.tags || '')
    .split(',')
    .map((x) => trim(x))
    .filter((x) => !isEmpty(x));

  // Additional data
  const {
    error, data: additionalData, loading,
  } = useQuery(FETCH_ADDITIONAL_DATA, {
    variables: {
      locale: i18n.language,
      filters: {
        tag: { in: tags },
      },
      sort: ['index:desc', 'name'],
    },
  });

  if (error) {
    throw error;
  }

  const isLoading = loading;
  const items = additionalData?.customerTestimonials?.data ?? [];
  colorScheme = ColorScheme.getColorScheme(colorScheme, 'default');
  const cssColorClass = `${colorScheme[0].toUpperCase()}${colorScheme.substring(1)}`;

  const colorMapped = MUI_COLOR_MAPPING[colorScheme];
  const cssColorMappedClass = `${colorMapped[0].toUpperCase()}${colorMapped.substring(1)}`;

  return (
    <div className={clsx(classes.root, classes[`root${cssColorClass}`])}>
      <Container className={classes.container}>
        <Typography
          align="center"
          className={classes.title}
          color={colorMapped}
          component="h2"
          variant="h3"
        >
          {t('components.contentTypes.CustomerTestimonialsBlock.customerTestimonials')}
        </Typography>
        {isLoading && (
          <>
            {Array.from(Array(3).keys()).map((_, index) => (
              <Box key={index} alignItems="center" display="flex" mb={5}>
                <Box flex={1}>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Box>
                <Box ml={3}>
                  <Skeleton height={100} variant="circle" width={100} />
                </Box>
              </Box>
            ))}
          </>
        )}
        {!isLoading && items.length > 0 && (
          <div>
            {items.map((item) => (
              <div key={item.id} className={classes.testimonial}>
                <Box
                  alignItems="center"
                  display="flex"
                >
                  <Box display="flex" flex={1} flexDirection="column">
                    <Hidden mdUp>
                      <Box display="flex" justifyContent="center" mb={2}>
                        <Avatar
                          alt={`${item.attributes.name} Bewertung`}
                          className={classes.avatar}
                          imgProps={{
                            loading: 'lazy',
                          }}
                          src={item.attributes.image?.data?.attributes.url}
                        >
                          {item.attributes.name[0]}
                        </Avatar>
                      </Box>
                    </Hidden>
                    <Box alignItems="center" display="flex" mb={2}>
                      <Box className={classes.quote}>
                        <span
                          className={clsx(
                            classes.quoteIcon,
                            classes[`quoteIcon${cssColorMappedClass}`],
                          )}
                        >
                          “
                        </span>
                      </Box>
                      <Typography
                        color={colorMapped}
                        component="h4"
                        variant="h6"
                      >
                        {item.attributes.url
                          ? (
                            <Link href={item.attributes.url} legacyBehavior>
                              <a rel="noopener nofollow" target="_blank">
                                {item.attributes.name}
                              </a>
                            </Link>
                          )
                          : item.attributes.name}
                      </Typography>
                      <Stars />
                    </Box>
                    <Box className={classes.textContainer}>
                      <Typography
                        color={colorMapped}
                        style={{ fontStyle: 'italic' }}
                        variant="body2"
                      >
                        {item.attributes.text}
                      </Typography>
                      {item.attributes.url && (
                        <Box mt={3}>
                          <Link href={item.attributes.url} legacyBehavior>
                            <Button
                              color="primary"
                              component="a"
                              rel="noopener nofollow"
                              target="_blank"
                              variant="contained"
                            >
                              {t('components.contentTypes.CustomerTestimonialsBlock.more')}
                            </Button>
                          </Link>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Hidden smDown>
                    <Box ml={3}>
                      <Avatar
                        alt={`${item.attributes.name} Bewertung`}
                        className={classes.avatar}
                        imgProps={{
                          loading: 'lazy',
                        }}
                        src={item.attributes.image?.data?.attributes.url}
                      >
                        {item.attributes.name[0]}
                      </Avatar>
                    </Box>
                  </Hidden>
                </Box>
                <Box display="flex" justifyContent="center" mt={5}>
                  <Divider
                    className={classes.divider}
                  />
                </Box>
              </div>
            ))}
          </div>
        )}
        <Box mt={10}>
          <Grid
            alignItems="center"
            container
            justify="center"
            spacing={3}
          >
            <Grid item md="auto" xs={12}>
              <Box display="flex" justifyContent="center">
                <Image
                  alt="Mobile Auszeichnung"
                  className={classes.sealImage}
                  isFluid
                  src="/images/seals/seal-mobile.svg"
                />
              </Box>
            </Grid>
            <Grid item md="auto" xs={12}>
              <Box display="flex" justifyContent="center">
                <Image
                  alt="Autoscout24 Auszeichnung"
                  className={classes.sealImage}
                  isFluid
                  src="/images/seals/seal-autoscout24.svg"
                />
              </Box>
            </Grid>
            <Grid item md="auto" xs={12}>
              <Box display="flex" justifyContent="center">
                <Image
                  alt="Mitsubishi Auszeichnung"
                  className={classes.sealImage}
                  isFluid
                  src="/images/seals/seal-mitsubishi.svg"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

// eslint-disable-next-line react/display-name
const Stars = memo(() => {
  const classes = useStyles();

  return (
    <Box display="flex" ml={2}>
      {Array.from(Array(5).keys()).map((_, index) => (
        <FaStar key={index} className={clsx([classes.starIcon])} />
      ))}
    </Box>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  rootPrimary: {
    backgroundColor: theme.palette.secondary.main,
  },
  rootSecondary: {
    backgroundColor: theme.palette.primary.main,
  },
  rootTertiary: {
    backgroundColor: theme.palette.tertiary.main,
  },
  rootQuaternary: {
    backgroundColor: theme.palette.quaternary.main,
  },
  rootLight: {
    backgroundColor: theme.palette.light.main,
  },
  rootDark: {
    backgroundColor: theme.palette.dark.main,
  },
  container: {
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  testimonial: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    '&:last-child $divider': {
      display: 'none',
    },
  },
  divider: {
    backgroundColor: 'rgba(255,255,255,0.25)',
    width: theme.spacing(10),
  },
  quote: {
    position: 'relative',
    width: 40,
    height: 40,
    marginRight: theme.spacing(1),
  },
  quoteIcon: {
    position: 'absolute',
    top: -7,
    left: 0,
    display: 'block',
    marginRight: theme.spacing(1),
    fontSize: 100,
    lineHeight: '100px',
    opacity: 0.25,
  },
  quoteIconInherit: {
    color: theme.palette.secondary.contrastText,
  },
  quoteIconPrimary: {
    color: theme.palette.secondary.contrastText,
  },
  quoteIconSecondary: {
    color: theme.palette.primary.contrastText,
  },
  textContainer: {
    flex: 1,
    paddingLeft: 40 + theme.spacing(1),
  },
  avatar: {
    width: 100,
    height: 100,
  },
  sealImage: {
    width: 260,
  },
  provenExpertRating: {
    transform: 'scale(1.25)',
  },
  starIcon: {
    fontSize: 20,
    display: 'block',
    color: theme.palette.primary.main,
  },
}));

CustomerTestimonialsBlock.typeName = 'ComponentContentCustomerTestimonials'; // Strapi element type
CustomerTestimonialsBlock.propTypes = {
  data: PropTypes.shape({
    tags: PropTypes.string,
    colorScheme: ColorScheme.propTypes,
  }).isRequired,
};
CustomerTestimonialsBlock.dataSchema = yup.object().shape({
  tags: yup.string().nullable(),
  color_scheme: ColorScheme.dataSchema.nullable(),
});
CustomerTestimonialsBlock.graphQlSchema = `
... on ${CustomerTestimonialsBlock.typeName} {
  id
  tags
  colorScheme {
    ${ColorScheme.graphQlSchema}
  }
}
`;

const FETCH_ADDITIONAL_DATA = gql`
query GetItems($filters: CustomerTestimonialFiltersInput, $sort: [String], $locale: I18NLocaleCode!) {
  customerTestimonials(filters: $filters, sort: $sort, locale: $locale) {
    data {
      id
      attributes {
        name
        text
        tag
        url
        index
        image {
          data {
            attributes {
              name
              width
              height
              formats
              url
            }
          }
        }
      }
    }
  }
}
`;

const MUI_COLOR_MAPPING = {
  default: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'inherit',
  quaternary: 'inherit',
  light: 'inherit',
  dark: 'secondary',
};
