/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';

import {
  Box,
  Button,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { FUEL_TYPE, TRANSMISSION_TYPE } from '@plugins/vehiclefleetapp';
import { FiArrowLeft, FiArrowRight, FiSearch } from 'react-icons/fi';
import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Virtual } from 'swiper';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import { getCarSearchRoute } from '@lib/routing';
import { isClientSide } from '@lib/utils/environment';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Link from '../atoms/Link';
import Select from '../atoms/Select';
import Container from '../atoms/Container';
import InventoryCarListItem, { GRAPHQL_REQUIRED_PROPS } from '../vehiclefleet/InventoryCarListItem';

SwiperCore.use([Virtual]);
SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);

const PAGE_QUERY_PARAM = 'isp';

export default function InventoryCarsBlock(props) {
  const { data } = props;
  const router = useRouter();
  const classes = useStyles();
  const {
    i18n,
    t,
  } = useTranslation();
  const isInitialMount = useRef(true);
  const [swiper, setSwiper] = useState();
  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const take = useItemPreviewCount();
  const [sort, setSort] = useState(SORT_OPTIONS[0].value);
  const [fuelType, setFuelType] = useState(null);
  const [transmission, setTransmission] = useState(null);

  const { elementData } = useContentElement(
    data,
    InventoryCarsBlock.dataSchema,
  );

  const {
    branches,
    inventoryCarFilters: filters,
    inventoryCarFilterGroups: filterGroups,
  } = elementData;

  const title = elementData.title ? omitBy(elementData.title, isNull) : null;

  if (title) {
    defaults(title, {
      textAlign: 'center',
      semanticVariant: 'h3',
      displayVariant: 'h3',
    });
  }

  // Additional data
  const {
    error: filterQueryError,
    data: filterData,
  } = useQuery(QUERY_CAR_SEARCH_FILTER_DATA, {
    variables: {},
  });

  const queryCarFilters = filterGroups.length > 0
    ? filterGroups.map((group) => ({
      filters: group.filters.map((filter) => ({
        condition: filter.condition,
        type: filter.type,
        value: filter.value,
      })),
    }))
    : [
      {
        filters: filters.map((filter) => ({
          condition: filter.condition,
          type: filter.type,
          value: filter.value,
        })),
      },
    ];

  const {
    error: additionalDataQueryError,
    data: additionalData,
    loading,
    networkStatus,
  } = useQuery(FETCH_ADDITIONAL_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        skip: 0,
        take: 100,
        sort,
        branches: branches?.data?.map((x) => parseInt(x.id, 10)) ?? [],
        filterGroups: queryCarFilters,
        options: {
          fuelType: fuelType || null,
          transmission: transmission || null,
        },
      },
    },
    onCompleted: () => {
      const initialSlide = getInitialSlide();

      if (initialSlide > 0) {
        swiper.slideTo(initialSlide);
      }
    },
  });

  const slideIndex = parseInt(router.query[PAGE_QUERY_PARAM] ?? 0, 10);

  useEffect(() => {
    if (!swiper || !additionalData) return;
    swiper.slideTo(slideIndex);
  }, [slideIndex, swiper, additionalData]);

  if (filterQueryError || additionalDataQueryError) {
    throw filterQueryError ?? additionalDataQueryError;
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  });

  useEffect(() => {
    if (isInitialMount.current) {
      return;
    }

    if (isNil(additionalData?.filteredCarsData.totalCount)) {
      return;
    }

    setTotalCount(additionalData?.filteredCarsData.totalCount);
  }, [additionalData?.filteredCarsData.totalCount]);

  const isLoading = loading || networkStatus < NetworkStatus.ready;
  const items = additionalData?.filteredCarsData.items ?? [];
  const isPurchasingEnabled = Boolean(additionalData?.carSearchConfig?.isPurchasingEnabled);

  const updatePageQueryParam = (index) => {
    const queryParams = new URLSearchParams(window.location.search);
    const hasQueryParam = queryParams.has(PAGE_QUERY_PARAM);
    queryParams.set(PAGE_QUERY_PARAM, index);
    const updatedPathname = `${router.asPath.split('?')[0]}?${queryParams.toString()}`;
    router[hasQueryParam ? 'replace' : 'push'](updatedPathname, undefined, { shallow: true });
    setCanGoPrevious(index > 0);
    setCanGoNext(index < swiper.snapGrid.length - 1);
  };

  const handleGoToPreviousPage = () => {
    updatePageQueryParam(swiper.activeIndex - 1);
  };
  const handleGoToNextPage = () => {
    updatePageQueryParam(swiper.activeIndex + 1);
  };
  const handleSlideChange = () => {
    updatePageQueryParam(swiper.activeIndex);
  };
  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };
  const handleChangeFuelType = (event) => {
    setFuelType(event.target.value);
  };
  const handleChangeTransmission = (event) => {
    setTransmission(event.target.value);
  };

  return (
    <div className={clsx(classes.root, { [classes.hasTitle]: Boolean(title) })}>
      <Container className={classes.container}>
        {title && (
          <div className={classes.titleContainer}>
            <TypographyElement align="center" data={title} />
          </div>
        )}
        <Box>
          <Box mb={3}>
            <FilterBar
              data={filterData?.carSearchFilterData}
              fuelType={fuelType}
              i18n={i18n}
              onChangeFuelType={handleChangeFuelType}
              onChangeSort={handleChangeSort}
              onChangeTransmission={handleChangeTransmission}
              sort={sort}
              t={t}
              transmission={transmission}
            />
          </Box>
          <Box className={classes.itemsContainer}>
            {totalCount === 0 && (
              <EmptyResultSet classes={classes} t={t} />
            )}
            <Hidden smDown>
              <Button
                className={clsx(
                  classes.paginationButton,
                  classes.paginationButtonLeft,
                )}
                disabled={!canGoPrevious}
                onClick={handleGoToPreviousPage}
                size="small"
              >
                <FiArrowLeft className={classes.paginationButtonIcon} />
              </Button>
            </Hidden>
            {isLoading && (
              <LoadingState classes={classes} count={take} />
            )}
            <Items
              classes={classes}
              count={take}
              isPurchasingEnabled={isPurchasingEnabled}
              items={items}
              onActiveIndexChange={handleSlideChange}
              setSwiper={setSwiper}
            />
            <Hidden smDown>
              <Button
                className={clsx(
                  classes.paginationButton,
                  classes.paginationButtonRight,
                )}
                disabled={!canGoNext}
                onClick={handleGoToNextPage}
                size="small"
              >
                <FiArrowRight className={classes.paginationButtonIcon} />
              </Button>
            </Hidden>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

function EmptyResultSet({
  t,
  classes,
}) {
  return (
    <Box alignItems="center" display="flex" justifyContent="center" pb={10} pt={5}>
      <Box mr={1}>
        <FiSearch className={classes.emptyResultsMessage} />
      </Box>
      <Typography className={classes.emptyResultsMessage}>
        {t('components.contentTypes.InventoryCarsBlock.emptyResultSet')}
      </Typography>
    </Box>
  );
}

function LoadingState({
  count,
  classes,
}) {
  return (
    <Grid alignItems="stretch" container spacing={5}>
      {Array.from(Array(count)
        .keys())
        .map((_, index) => (
          <Grid key={index} className={classes.gridItem} item xs={12 / count}>
            <InventoryCarListItem useSkeleton />
          </Grid>
        ))}
    </Grid>
  );
}

function FilterBar(props) {
  const {
    t,
    i18n,
    data,
    sort,
    fuelType,
    transmission,
    onChangeSort,
    onChangeFuelType,
    onChangeTransmission,
  } = props;
  const columnConfig = {
    xs: 12,
    sm: 6,
    md: 3,
    lg: 3,
  };

  const {
    fuelTypes = [],
    transmissions = [],
  } = data ?? {};

  return (
    <Grid alignItems="center" container spacing={3}>
      <Grid
        item
        lg={columnConfig.lg}
        md={columnConfig.md}
        sm={columnConfig.sm}
        xs={columnConfig.xs}
      >
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel id="vehicle-category-select">
            {t('components.contentTypes.InventoryCarsBlock.sorting')}
          </InputLabel>
          <Select
            onChange={onChangeSort}
            value={sort}
            variant="outlined"
          >
            {SORT_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option[`label_${i18n.language}`]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        lg={columnConfig.lg}
        md={columnConfig.md}
        sm={columnConfig.sm}
        xs={columnConfig.xs}
      >
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel id="vehicle-category-select">
            {t('components.contentTypes.InventoryCarsBlock.fuel')}
          </InputLabel>
          <Select
            onChange={onChangeFuelType}
            value={fuelType ?? ''}
            variant="outlined"
          >
            <MenuItem value="">
              {t('components.contentTypes.InventoryCarsBlock.allFuels')}
            </MenuItem>
            {fuelTypes.map((option) => (
              <MenuItem key={option} value={option}>
                {FUEL_TYPE[option]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        lg={columnConfig.lg}
        md={columnConfig.md}
        sm={columnConfig.sm}
        xs={columnConfig.xs}
      >
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel id="vehicle-category-select">
            {t('components.contentTypes.InventoryCarsBlock.transmission')}
          </InputLabel>
          <Select
            onChange={onChangeTransmission}
            value={transmission ?? ''}
            variant="outlined"
          >
            <MenuItem value="">
              {t('components.contentTypes.InventoryCarsBlock.allTransmissions')}
            </MenuItem>
            {transmissions.map((option) => (
              <MenuItem key={option} value={option}>
                {TRANSMISSION_TYPE[option]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        lg={columnConfig.lg}
        md={columnConfig.md}
        sm={columnConfig.sm}
        xs={columnConfig.xs}
      >
        <Link href={getCarSearchRoute(i18n.language)}>
          <Button
            color="primary"
            component="a"
            endIcon={<FiArrowRight />}
            fullWidth
          >
            {t('components.contentTypes.InventoryCarsBlock.detailedSearch')}
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}

function Items(props) {
  const {
    setSwiper,
    count,
    items,
    isPurchasingEnabled,
    classes,
    onActiveIndexChange,
  } = props;
  const theme = useTheme();

  const onSlideChangeHandler = (swiper) => {
    swiper.pagination.bullets.forEach((item, index) => {
      if (index < swiper.activeIndex) {
        item.className = 'swiper-pagination-bullet swiper-pagination-bullet-active';
      }
    });
  };

  return (
    <Swiper
      className={classes.swipeContainer}
      modules={[Pagination]}
      onActiveIndexChange={onActiveIndexChange}
      onSlideChange={onSlideChangeHandler}
      onSwiper={(swiper) => setSwiper(swiper)}
      pagination={{ clickable: true }}
      slidesPerView={count}
      spaceBetween={theme.spacing(2.5)}
      virtual={{
        addSlidesAfter: 3,
        addSlidesBefore: 3,
      }}
    >
      {items.map((item, index) => (
        <SwiperSlide
          key={item.id}
          className={classes.swipeSlideItem}
          virtualIndex={index}
        >
          <InventoryCarListItem
            isPurchasingEnabled={isPurchasingEnabled}
            item={item}
          />

        </SwiperSlide>
      ))}
    </Swiper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  hasTitle: {},
  container: {},
  emptyResultsMessage: {
    color: theme.palette.text.primary,
    opacity: 0.75,
  },
  paginationButton: {
    position: 'absolute',
    display: 'block',
    width: 50,
    minWidth: 0,
    top: 7,
    bottom: 30,
  },
  paginationButtonLeft: {
    left: -50 - theme.spacing(1),
  },
  paginationButtonRight: {
    right: -50 - theme.spacing(1),
  },
  paginationButtonIcon: {
    width: 20,
    height: 20,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    paddingBottom: theme.spacing(5),
  },
  itemsContainer: {
    position: 'relative',
  },
  swipeContainer: {
    padding: 7,
    margin: -7,
    paddingTop: 20,
    '& .swiper-wrapper': {
      alignItems: 'stretch',
    },
    '& .swiper-pagination-bullet-active': {
      background: theme.palette.primary.main,
    },
    '& .swiper-container': {
      position: 'relative',
    },

    '& .swiper-pagination': {
      position: 'absolute',
      top: 0,
      display: 'flex',
      height: '10px',
      zIndex: 15,
    },

    '& .swiper-pagination-bullet': {
      borderRadius: 15,
      width: '100%',
      background: theme.palette.primary.main,

    },

    swipeSlideItem: {
      height: 'auto',
      display: 'flex',
    },
  },
}));

function useItemPreviewCount() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLg = useMediaQuery(theme.breakpoints.up('md'));

  let previewCount = 1;

  if (isLg) {
    previewCount = 3;
  } else if (isSm) {
    previewCount = 2;
  }

  return previewCount;
}

InventoryCarsBlock.typeName = 'ComponentContentInventoryCars'; // Strapi element type
InventoryCarsBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    inventoryCarFilters: PropTypes.arrayOf(PropTypes.shape({
      condition: PropTypes.oneOf(['Include', 'Exclude']).isRequired,
      type: PropTypes.oneOf(['Brand', 'Model', 'Search']).isRequired,
      value: PropTypes.string.isRequired,
    })),
  }).isRequired,
};
InventoryCarsBlock.dataSchema = yup.object()
  .shape({
    title: TypographyElement.dataSchema.nullable(),
    inventoryCarFilters: yup.array()
      .of(yup.object()
        .shape({
          condition: yup.string()
            .required()
            .oneOf(['Include', 'Exclude']),
          type: yup.string()
            .required(),
          value: yup.string()
            .required(),
        })),
  });
InventoryCarsBlock.graphQlSchema = `
... on ${InventoryCarsBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  branches {
    data {
      id
    }
  }
  inventoryCarFilters: filters {
    condition
    type
    value
  }
  inventoryCarFilterGroups: filterGroups {
    filters {
      condition
      type
      value
    }
  }
}
`;

function getInitialSlide() {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has(PAGE_QUERY_PARAM)) {
    return parseInt(queryParams.get(PAGE_QUERY_PARAM) ?? 0, 10);
  }
  return 0;
}

const FETCH_ADDITIONAL_DATA = gql`
  query GetInventoryCars($input: FilteredCarsInput!) {
    filteredCarsData(input: $input) {
      items {
        ${GRAPHQL_REQUIRED_PROPS}
      }
      totalCount
    }
    carSearchConfig {
      data {
        attributes {
          isPurchasingEnabled
        }
      }
    }
  }
`;

export const QUERY_CAR_SEARCH_FILTER_DATA = gql`
  query GetSearchFilterData($fuelType: String, $transmission: String) {
    carSearchFilterData(fuelType: $fuelType, transmission: $transmission) {
      fuelTypes
      fuelType
      transmissions
      transmission
    }
  }
`;

const SORT_OPTIONS = [
  {
    value: 'is_immediately_available:desc,price:asc',
    label_de: 'Verfügbarkeit',
    label_en: 'Availability',
  },
  {
    value: 'price:asc',
    label_de: 'Preis aufsteigend',
    label_en: 'Price ascending',
  },
  {
    value: 'price:desc',
    label_de: 'Preis absteigend',
    label_en: 'Price descending',
  },
];
