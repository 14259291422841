import * as yup from 'yup';

import {
  Box,
  Button,
  Hidden,
  isWidthDown,
  Typography,
  useTheme,
  withWidth,
  useMediaQuery,
  Grid,
} from '@material-ui/core';
import {
  LinkElement,
  MediaElement,
  TypographyElement,
  useContentElement,
} from '@plugins/next-cms-core';
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Virtual } from 'swiper';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import { makeStyles } from '@material-ui/styles';
import omitBy from 'lodash/omitBy';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import PageInsetTop from '@components/atoms/PageInsetTop';
import { useTranslation } from 'react-i18next';
import Container from '../atoms/Container';
import Image from '../atoms/Image';
import Link from '../atoms/Link';

SwiperCore.use([Virtual]);
SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);

const HEADER_HEIGHT = 140;
const HEADER_MAX_HEIGHT = 800;

function PageHeaderBlock(props) {
  const {
    data,
    width,
    context: { page },
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [swiper, setSwiper] = useState();
  const [canGoPrevious, setCanGoPrevious] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const classes = useStyles({
    isTopContentOffset: page.isTopContentOffset || page.attributes?.isTopContentOffset,
  });
  const currentIndex = useRef(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const { elementData } = useContentElement(
    data,
    PageHeaderBlockWrapper.dataSchema,
  );

  const {
    pageHeaderSlides,
    enableAutoplay,
    enablePromotionDisplay,
    isContained,
    visibility,
  } = elementData;
  let { height } = elementData;
  const maxWidth = '1500px';

  if (!height || height <= 0) {
    height = HEADER_MAX_HEIGHT;
  }

  // Scale height on mobile
  if (isWidthDown('sm', width)) {
    height /= 2;
  }

  const handleGoToPreviousPage = () => {
    swiper.slidePrev();
  };
  const handleGoToNextPage = () => {
    swiper.slideNext();
  };

  const handleSlideChange = () => {
    setSlideIndex(swiper.activeIndex);
    setCanGoPrevious(swiper.activeIndex > 0);
    setCanGoNext(swiper.activeIndex < swiper.snapGrid.length - 1);
  };

  if (isMobile && visibility === 'desktop') {
    return page.isTopContentOffset ? null : <PageInsetTop />;
  }
  if (!isMobile && visibility === 'mobile') {
    return page.isTopContentOffset ? null : <PageInsetTop />;
  }

  return (
    <div
      className={classes.root}
      style={{
        minHeight: height,
        maxWidth: isContained ? maxWidth : '',
      }}
    >
      {pageHeaderSlides.length > 1 && canGoPrevious && (
        <Hidden smDown>
          <Button
            className={clsx(
              classes.paginationButton,
              classes.paginationButtonLeft,
            )}
            disabled={!canGoPrevious}
            onClick={handleGoToPreviousPage}
            size="small"
          >
            <FiChevronLeft className={classes.paginationButtonIcon} />
            <FiChevronLeft className={classes.paginationButtonIcon} />
          </Button>
        </Hidden>
      )}
      <Swiper
        allowTouchMove={pageHeaderSlides.length > 1}
        autoplay={pageHeaderSlides.length > 1 && { delay: 5000 }}
        className={classes.swipeContainer}
        modules={[Pagination]}
        onActiveIndexChange={handleSlideChange}
        onSwiper={(newValue) => setSwiper(newValue)}
        pagination={{ clickable: true }}
        slidesPerView={1}
        spaceBetween={theme.spacing(2.5)}
        virtual={{
          addSlidesAfter: 3,
          addSlidesBefore: 3,
        }}
      >
        {pageHeaderSlides.map((item, index) => (
          <SwiperSlide
            key={item.id}
            className={classes.swipeSlideItem}
            virtualIndex={index}
          >
            <Slide
              key={item.id}
              classes={classes}
              currentIndex={currentIndex}
              height={height}
              isMobile={isWidthDown('sm', width)}
              slide={item}
              theme={theme}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {pageHeaderSlides.length > 1 && canGoNext && (
        <Hidden smDown>
          <Button
            className={clsx(
              classes.paginationButton,
              classes.paginationButtonRight,
            )}
            disabled={!canGoNext}
            onClick={handleGoToNextPage}
            size="small"
          >
            <FiChevronRight className={classes.paginationButtonIcon} />
            <FiChevronRight className={classes.paginationButtonIcon} />
          </Button>
        </Hidden>
      )}
      {enablePromotionDisplay && (
        <>
          <Hidden smDown>
            <div className={classes.promotionRoot}>
              <Link href="/unternehmen/auszeichnungen-und-partner" legacyBehavior={false}>
                <Box className={classes.promotionDisplay}>
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    <Box mr={2}>
                      <Image
                        alt="Füchse Berlin Logo"
                        className={classes.promotionImage}
                        height="70"
                        isFluid
                        isSquare
                        src="/images/promotion/fuechse-logo_small.png"
                        width="70"
                      />
                    </Box>
                    <Box>
                      <Typography
                        color="secondary"
                        dangerouslySetInnerHTML={{ __html: t('components.contentTypes.PageHeaderBlock.officialAutomotivePartner', { break: '<br />' }) }}
                        variant="caption"
                      />
                    </Box>
                  </Box>
                  <Box mt={1}>
                    <Box mb={1} textAlign="center">
                      <Typography color="secondary" style={{ fontWeight: 'bold' }} variant="caption">
                        Ausgezeichnet von
                      </Typography>
                    </Box>
                    <Grid alignItems="center" container spacing={2}>
                      <Grid item xs={4}>
                        <Image
                          alt="Mobile.De Logo"
                          className={classes.promotionImage}
                          height="39"
                          isFluid
                          isSquare
                          src="/images/promotion/mobile-de-logo.svg"
                          width="164"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Image
                          alt="IHK Logo"
                          className={classes.promotionImage}
                          height="280"
                          isFluid
                          isSquare
                          src="/images/promotion/ihk-logo.svg"
                          width="561"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Image
                          alt="KFZ Betrieb Logo"
                          className={classes.promotionImage}
                          height="68"
                          isFluid
                          isSquare
                          src="/images/promotion/kfz-gewerbe-logo.svg"
                          width="274"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Link>
            </div>
          </Hidden>
          <Hidden mdUp>
            {pageHeaderSlides[slideIndex]?.disclaimer && (
              <div className={classes.disclaimerContainer}>
                <Container>
                  <TypographyElement
                    data={getDisclaimer(pageHeaderSlides[slideIndex]?.disclaimer)}
                  />
                </Container>
              </div>
            )}
            <Box alignSelf="stretch" my={1}>
              <Container>
                <Link href="/blog/ein-fuchs-faehrt-schimmel" legacyBehavior={false}>
                  <Box alignItems="center" display="flex">
                    <Box mr={2}>
                      <Image
                        alt="Füchse Berlin Logo"
                        className={classes.promotionImage}
                        height="50"
                        isFluid
                        src="/images/promotion/fuechse-logo_small.png"
                        width="50"
                      />
                    </Box>
                    <Box>
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'components.contentTypes.PageHeaderBlock.officialAutomotivePartner',
                            { break: '' },
                          ),
                        }}
                        variant="caption"
                      />
                    </Box>
                  </Box>
                  <Box className={classes.promotionImageContainer} mt={1} p={1}>
                    <Box mb={1}>
                      <Typography style={{ fontWeight: 'bold' }} variant="caption">
                        Ausgezeichnet von
                      </Typography>
                    </Box>
                    <Grid alignItems="center" container spacing={5}>
                      <Grid item xs={4}>
                        <Image
                          alt="Mobile.De Logo"
                          height="39"
                          isFluid
                          isSquare
                          src="/images/promotion/mobile-de-logo-dark.svg"
                          width="164"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Image
                          alt="IHK Logo"
                          height="280"
                          isFluid
                          isSquare
                          src="/images/promotion/ihk-logo-dark.svg"
                          width="561"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Image
                          alt="KFZ Betrieb Logo"
                          height="68"
                          isFluid
                          isSquare
                          src="/images/promotion/kfz-gewerbe-logo-dark.svg"
                          width="274"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Link>
              </Container>
            </Box>
          </Hidden>
        </>
      )}
    </div>
  );
}

const PageHeaderBlockWrapper = withWidth()(PageHeaderBlock);

export default PageHeaderBlockWrapper;

function Slide(props) {
  const {
    isMobile,
    height,
    slide,
    classes,
    theme,
    currentIndex,
  } = props;
  const {
    titlePosition,
    titleOffsetX,
    titleOffsetY,
    titleBackground,
    link,
    mobileMedia,
  } = slide;
  let { media } = slide;

  let title = null;
  let subtitle = null;
  let disclaimer = null;

  if (isMobile && mobileMedia) {
    media = mobileMedia;
  }

  if (slide.title) {
    title = omitBy(slide.title, isNull);
    defaults(title, {
      semanticVariant: 'h1',
      displayVariant: 'h1',
    });
  }

  if (slide.subtitle) {
    subtitle = omitBy(slide.subtitle, isNull);
    defaults(subtitle, {
      semanticVariant: 'h5',
      displayVariant: 'h5',
    });
  }

  if (slide.disclaimer) {
    disclaimer = getDisclaimer(slide.disclaimer);
  }

  const handleClick = (e) => {
    if (currentIndex.current !== Math.round(currentIndex.current)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const titleContainerStyle = {
    transform: `translate(${titleOffsetX ?? 0}%, ${titleOffsetY ?? 0}%)`,
  };

  return (
    <div className={classes.slideContainer}>
      <div className={classes.mediaContainer}>
        <MediaElement
          data={media}
          isAbsoluteFill
        />
      </div>
      <Container
        className={clsx(
          classes.contentContainer,
          classes[`contentPosition${titlePosition}`],
        )}
        style={{ maxHeight: height }}
      >
        {(title || subtitle) && (
          <div
            className={clsx(
              classes.titleContainer,
              classes[`titleBackground_${titleBackground ?? 'default'}`],
            )}
            style={titleContainerStyle}
          >
            {subtitle && (
              <TypographyElement
                color="secondary"
                data={subtitle}
              />
            )}
            {title && (
              <TypographyElement
                color="secondary"
                data={title}
              />
            )}
          </div>
        )}
        {disclaimer && (
          <Hidden smDown>
            <div className={classes.disclaimerContainer}>
              <TypographyElement
                color="secondary"
                data={disclaimer}
              />
            </div>
          </Hidden>
        )}
      </Container>
      {link && (
        <LinkElement data={link} onClick={handleClick}>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a className={classes.link} />
        </LinkElement>
      )}
    </div>
  );
}

const CONTENT_CONTAINER_PADDING_FACTOR = 5;
const CONTENT_MAX_WIDTH = 2000;
const heightStyleMobile = 'calc(80vh - 45px)';
const heightStyleDesktop = 'calc(100vh - 45px)';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
  slideContainer: {
    position: 'relative',
    flex: 1,
  },
  mediaContainer: {
    zIndex: -1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    zIndex: 1,
    pointerEvents: 'none',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: heightStyleMobile,
    [theme.breakpoints.up('lg')]: {
      height: heightStyleDesktop,
    },
    paddingTop: HEADER_HEIGHT + theme.spacing(CONTENT_CONTAINER_PADDING_FACTOR),
    '& * > p': {
      margin: 0,
    },
  },
  link: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  disclaimerContainer: {
    backdropFilter: 'saturate(180%) blur(3px)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.35)',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
    },
  },
  swipeContainer: {
    width: '100%',
    '& .swiper-wrapper': {
      alignItems: 'stretch',
    },
    '& .swiper-pagination-bullet-active': {
      background: theme.palette.primary.main,
    },
    '& .swiper-container': {
      position: 'relative',
    },

    '& .swiper-pagination': {
      position: 'absolute',
      top: 0,
      display: 'flex',
      height: '10px',
      zIndex: 15,
    },

    '& .swiper-pagination-bullet': {
      borderRadius: 15,
      width: '100%',
      background: theme.palette.primary.main,

    },

    swipeSlideItem: {
      height: 'auto',
      display: 'flex',
    },
  },
  paginationButton: {
    fontSize: theme.typography.pxToRem(45),
    position: 'absolute',
    display: 'block',
    minWidth: 0,
    top: '50%',
    zIndex: 2,
    color: 'white',
    backgroundColor: 'transparent',
    '&.Mui-disabled': {
      color: 'white',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  paginationButtonLeft: {
    left: theme.spacing(10),
    '& svg': {
      filter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))',
    },
    '& svg:nth-child(2)': {
      marginLeft: theme.spacing(-4.5),
      display: 'none',
    },
    '&:hover svg:nth-child(2)': {
      display: 'inline',
    },
  },
  paginationButtonRight: {
    right: theme.spacing(10),
    '& svg': {
      filter: 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4))',
    },
    '& svg:first-child': {
      marginRight: theme.spacing(-4.5),
      display: 'none',
    },
    '&:hover svg:first-child': {
      display: 'inline',
    },
  },
  contentPositionTopLeft: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  contentPositionTopCenter: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  contentPositionTopRight: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  contentPositionCenterLeft: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  contentPositionCenterCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentPositionCenterRight: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  contentPositionBottomLeft: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  contentPositionBottomCenter: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  contentPositionBottomRight: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  promotionRoot: {
    pointerEvents: 'none',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: '100%',
    maxWidth: CONTENT_MAX_WIDTH,
    zIndex: 2,
    '& a': {
      pointerEvents: 'all',
    },
  },
  promotionDisplay: (props) => ({
    position: 'absolute',
    top: props.isTopContentOffset ? 45 : 160,
    left: theme.spacing(2),
    backgroundColor: 'rgba(0,0,0,0.45)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  }),
  promotionImageContainer: {
  },
  promotionImage: {
    width: 50,
    [theme.breakpoints.up('lg')]: {
      width: 70,
    },
  },
  titleContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  titleBackground_default: {},
  titleBackground_primary: {
    backgroundColor: theme.palette.primary.light,
  },
  titleBackground_light: {
    backgroundColor: 'rgba(255,255,255,0.45)',
    // textShadow: '2px 2px 10px rgba(255, 255, 255, 0.95),
    // 2px 2px 20px rgba(255, 255, 255, 0.85), 2px 2px 30px rgba(255, 255, 255, 0.75)'
  },
  titleBackground_dark: {
    backgroundColor: 'rgba(0,0,0,0.45)',
    // textShadow: '2px 2px 10px rgba(0, 0, 0, 0.95),
    // 2px 2px 20px rgba(0, 0, 0, 0.85), 2px 2px 30px rgba(0, 0, 0, 0.75)'
  },
}));

PageHeaderBlockWrapper.typeName = 'ComponentContentPageHeader'; // Strapi element type
PageHeaderBlock.propTypes = {
  data: PropTypes.shape({
    height: PropTypes.number,
    enableAutoplay: PropTypes.bool.isRequired,
    enablePromotionDisplay: PropTypes.bool.isRequired,
    pageHeaderSlides: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      titlePosition: PropTypes.string.isRequired,
      titleBackground: PropTypes.oneOf([
        null, 'default', 'primary', 'light', 'dark',
      ]),
      link: LinkElement.propTypes,
      media: MediaElement.propTypes,
      mobileMedia: MediaElement.propTypes,
      title: TypographyElement.propTypes,
      subtitle: TypographyElement.propTypes,
      disclaimer: TypographyElement.propTypes,
    })),
  }).isRequired,
};
PageHeaderBlockWrapper.dataSchema = yup.object()
  .shape({
    height: yup.number()
      .nullable(),
    enableAutoplay: yup.bool(),
    enablePromotionDisplay: yup.bool(),
    pageHeaderSlides: yup.array()
      .of(yup.object()
        .shape({
          id: yup.string()
            .required(),
          titlePosition: yup.string()
            .required(),
          titleBackground: yup.string()
            .nullable()
            .oneOf([
              null, 'default', 'primary', 'light', 'dark',
            ]),
          link: LinkElement.dataSchema.nullable(),
          media: MediaElement.dataSchema,
          mobileMedia: MediaElement.dataSchema.nullable(),
          title: TypographyElement.dataSchema.nullable(),
          subtitle: TypographyElement.dataSchema.nullable(),
          disclaimer: TypographyElement.dataSchema.nullable(),
        })),
  });
PageHeaderBlockWrapper.graphQlSchema = `
... on ${PageHeaderBlockWrapper.typeName} {
  id
  height
  enableAutoplay
  enablePromotionDisplay
  isContained
  visibility
  pageHeaderSlides: slides(pagination: { limit: 100 }) {
    id
    titlePosition
    titleOffsetX
    titleOffsetY
    titleBackground
    link {
      ${LinkElement.graphQlSchema}
    }
    media {
      ${MediaElement.graphQlSchema}
    }
    mobileMedia {
      ${MediaElement.graphQlSchema}
    }
    title {
      ${TypographyElement.graphQlSchema}
    }
    subtitle {
      ${TypographyElement.graphQlSchema}
    }
    disclaimer {
      ${TypographyElement.graphQlSchema}
    }
  }
}
`;

function getDisclaimer(disclaimer) {
  disclaimer = omitBy(disclaimer, isNull);
  defaults(disclaimer, {
    semanticVariant: 'div',
    displayVariant: 'caption',
  });
  return disclaimer;
}
