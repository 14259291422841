import React, { forwardRef } from 'react';

import NativeButton from '@material-ui/core/Button';

function Button(props, ref) {
  return (
    <NativeButton ref={ref} {...props} />
  );
}

export default forwardRef(Button);

Button.propTypes = {
  ...NativeButton.propTypes,
};
Button.defaultProps = {
  ...NativeButton.defaultProps,
};
