import * as yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  FiClock,
  FiExternalLink,
  FiMail,
  FiMapPin,
  FiPhone,
} from 'react-icons/fi';
import { useState } from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import defaults from 'lodash/defaults';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Virtual } from 'swiper';
import { MediaElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';
import { trackEvent } from '@components/organisms/Tracking';
import clsx from 'clsx';
import Link from '../atoms/Link';
import Container from '../atoms/Container';
import Image from '../atoms/Image';

SwiperCore.use([Virtual]);
SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);

export default function BranchBlock(props) {
  const { data } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    BranchBlock.dataSchema,
  );

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  return (
    <div className={classes.variantCard}>
      <Container>
        {title && (
          <Box mb={5}>
            <TypographyElement data={title} />
          </Box>
        )}
        <Grid
          alignItems="center"
          container
          spacing={3}
        >
          <Grid item md={6} xs={12}>
            <Branches
              branch={elementData.branch.data}
              classes={classes}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ImageBlock classes={classes} data={elementData} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function Branches(props) {
  const {
    branch,
    classes,
  } = props;
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        {branch.attributes.addressStreet && (
          <Box>
            <Grid
              alignItems="center"
              container
              spacing={3}
            >
              <Grid item sm="auto" style={{ flex: 1 }} xs={12}>
                <Box alignItems="center" display="flex">
                  <Box mr={1}>
                    <FiMapPin className={classes.addressIcon} />
                  </Box>
                  <Typography component="div" variant="body2">
                    <strong>{branch.attributes.addressStreet}</strong>
                    {', '}
                    {branch.attributes.addressZipcode}
                    {' '}
                    {branch.attributes.addressCity}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm="auto" xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={openRoute(
                    `${branch.attributes.addressStreet}, ${branch.attributes.addressZipcode} ${branch.attributes.addressCity}, ${branch.attributes.addressCountry}`,
                  )}
                  startIcon={<FiExternalLink />}
                  variant="outlined"
                >
                  {t('components.contentTypes.BranchBlock.directions')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box mt={2}>
          <Typography variant="h6">
            {t('components.contentTypes.BranchBlock.workshop')}
          </Typography>
          <BranchDepartment
            businessHours={branch.attributes.businessHoursWorkshop}
            email={branch.attributes.workshopEmail}
            phone={branch.attributes.workshopPhone}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6">
            {t('components.contentTypes.BranchBlock.sale')}
          </Typography>
          <BranchDepartment
            businessHours={branch.attributes.businessHoursSales}
            email={branch.attributes.primaryEmail}
            phone={branch.attributes.salesPhone}
          />
        </Box>
        <Box mt={3}>
          <Link href={branch.attributes.appointmentUrl} legacyBehavior>
            <Button
              color="primary"
              component="a"
              variant="contained"
            >
              {t('components.contentTypes.BranchBlock.onlineScheduler')}
            </Button>
          </Link>
        </Box>
        <Box className={classes.carBrandsContainer} mt={5}>
          <Grid container spacing={4}>
            <Grid item xs={1} />
            <Grid item xs={2}>
              <Image
                alt="Hyundai"
                className={clsx(
                  classes.carBrandLogoHyundai,
                )}
                isFluid
                src="/images/car-brands/hyundai-motor-company-logo-2.svg"
              />
            </Grid>
            <Grid item xs={2}>
              <Image
                alt="MG Motor"
                className={clsx(
                  classes.carBrandLogoMG,
                )}
                isFluid
                src="/images/car-brands/mgmotors-logo.svg"
              />
            </Grid>
            <Grid item xs={2}>
              <Image
                alt="Mitsubishi"
                className={clsx(
                  classes.carBrandLogoMitsubishi,
                )}
                isFluid
                src="/images/car-brands/mitsubishi-logo.svg"
              />
            </Grid>
            <Grid item xs={2}>
              <Image
                alt="FUSO"
                className={clsx(
                  classes.carBrandLogoFuso,
                )}
                isFluid
                src="/images/car-brands/mitsubishi-fuso-logo.svg"
              />
            </Grid>
            <Grid item xs={2}>
              <Image
                alt="Maxus"
                className={clsx(
                  classes.carBrandLogoMaxus,
                )}
                isFluid
                src="/images/car-brands/maxus-logo.png"
              />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

function BranchDepartment(props) {
  const { businessHours, phone, email } = props;
  const trimTime = (time) => time.substring(0, 5);
  const { t } = useTranslation();

  return (
    <div>
      {phone && (
        <Box>
          <Link href={`tel:${phone}`} legacyBehavior>
            <Button
              color="primary"
              component="a"
              onClick={() => {
                trackEvent('Contact');
                trackEvent('ContactCustom', { channel: 'Phone', origin: 'Branch' });
              }}
              size="small"
              startIcon={<FiPhone />}
              variant="text"
            >
              {phone}
            </Button>
          </Link>
        </Box>
      )}
      {email && (
        <Box>
          <Link href={`mailto:${email}`} legacyBehavior>
            <Button
              color="primary"
              component="a"
              onClick={() => {
                trackEvent('Contact');
                trackEvent('ContactCustom', { channel: 'Email', origin: 'Branch' });
              }}
              size="small"
              startIcon={<FiMail />}
              variant="text"
            >
              {email}
            </Button>
          </Link>
        </Box>
      )}
      {businessHours.length > 0 && (
        <Box mt={1} style={{ maxWidth: 300 }}>
          <Typography gutterBottom variant="subtitle2">
            Öffnungszeiten
          </Typography>
          <Box display="flex">
            <Box mr={2} mt={0.5}>
              <FiClock className="icon" />
            </Box>
            <Box flex={1}>
              {businessHours.map((item) => (
                <Grid key={item.id} container>
                  <Grid item xs={4}>
                    {`${DAY_MAPPING[item.dayRangeStart]}.`}
                    {item.dayRangeEnd && ` - ${DAY_MAPPING[item.dayRangeEnd]}.`}
                  </Grid>
                  <Grid item xs={8}>
                    {trimTime(item.dayTimeStart)}
                    {item.dayTimeEnd && ` - ${trimTime(item.dayTimeEnd)}`}
                    {' '}
                    {t('components.contentTypes.BranchBlock.clock')}
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
}

function ImageBlock(props) {
  const { classes, data } = props;
  const {
    media,
  } = data;
  const [swiper, setSwiper] = useState();

  if (media.length === 0) {
    return null;
  }

  if (media.length === 1) {
    const firstMedia = media[0];

    return (
      <Box
        alignItems="center"
        className={classes.mediaItem}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <MediaElement data={firstMedia} />
        {firstMedia.caption && (
          <div className={classes.mediaLabel}>
            <Typography variant="caption">
              {firstMedia.caption}
            </Typography>
          </div>
        )}
      </Box>
    );
  }

  return (
    <Swiper
      autoHeight
      autoplay={{
        delay: 5000,
      }}
      className={classes.swipeContainer}
      onSwiper={(newValue) => setSwiper(newValue)}
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      slidesPerView={1}
    >
      {media.map((mediaItem) => (
        <SwiperSlide
          key={mediaItem.id}
          className={classes.swipeSlideItem}
        >
          <MediaElement data={mediaItem} />
          <div className={classes.mediaLabel}>
            <Typography variant="caption">
              {mediaItem.caption}
            </Typography>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

const DAY_MAPPING = {
  monday: 'Mo',
  tuesday: 'Di',
  wednesday: 'Mi',
  thursday: 'Do',
  friday: 'Fr',
  saturday: 'Sa',
  sunday: 'So',
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  carBrandsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  carBrandLogoHyundai: { width: 195 },
  carBrandLogoMG: { width: 145 },
  carBrandLogoFuso: { width: 145 },
  carBrandLogoMitsubishi: { width: 145 },
  swipeContainer: {
    paddingBottom: theme.spacing(3),
  },
  mediaContainer: {
    zIndex: 0,
  },
  mediaLabel: {
    flex: 1,
    marginTop: theme.spacing(0.5),
  },
  variantCard: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    '& $mediaContainer': {
      transform: 'scale(1.2)',
    },
    '& $mediaLabel': {
      maxWidth: '70%',
    },
  },
}));

BranchBlock.typeName = 'ComponentContentBranch'; // Strapi element type
BranchBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    media: yup.array(MediaElement.dataSchema).nullable(),
  }).isRequired,
};
BranchBlock.dataSchema = yup.object().shape({
  id: yup.number().required(),
  title: TypographyElement.dataSchema.nullable(),
  media: yup.array(MediaElement.dataSchema).nullable(),

});
BranchBlock.graphQlSchema = `
... on ${BranchBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  media {
    ${MediaElement.graphQlSchema}
  }
  branch {
    data {
      id
      attributes {
        addressCity
        addressCountry
        addressStreet
        addressZipcode
        appointmentUrl
        branchId
        geocodeLat
        geocodeLng
        name
        pageUrl
        primaryEmail
        primaryPhone
        salesPhone
        workshopEmail
        workshopPhone
        businessHoursSales {
          id
          dayRangeEnd
          dayRangeStart
          dayTimeEnd
          dayTimeStart
        }
        businessHoursWorkshop {
          id
          dayRangeEnd
          dayRangeStart
          dayTimeEnd
          dayTimeStart
        }
      }
    }
  }
}
`;

function openRoute(destination) {
  return () => {
    const isAppleUser = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
    let url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(destination)}`;

    if (isAppleUser) {
      url = `http://maps.apple.com/?q=${encodeURIComponent(destination)}`;
    }

    const win = window.open(url, '_blank');
    win.focus();
  };
}
